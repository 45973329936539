<template>
  <div class="px-1">
    <v-card outlined class="rounded-lg mb-5" v-if="!process.run">
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form>
            <p class="text-h6">Profile Trainer</p>
            <v-col>
              <p>Trainer Name</p>
              <ValidationProvider name="Fullname" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  flat
                  v-model="form.fullname"
                  :error-messages="errors"
                  placeholder="Tuliskan disini"
                  color="primary">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <p>Trainer Email</p>
                <v-text-field
                  outlined
                  flat
                  disabled
                  v-model="form.email"
                  :error-messages="errors"
                  placeholder="Tuliskan disini"
                  hint="Email tidak dapat diubah"
                  persistent-hint
                  color="primary">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider name="Nomor Telepon" rules="required|numeric|min:10|max:15" v-slot="{ errors }">
                <p>Trainer Phone Number</p>
                <v-text-field
                  outlined
                  flat
                  type="number"
                  v-model="form.phone_number"
                  :error-messages="errors"
                  placeholder="Tuliskan disini"
                  color="primary">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <v-select 
                label="Pilih Jenis Program"
                outlined  
                color="primary" 
                :items="[{
                  id: 'school',
                  name: 'Sekolah'
                }, {
                  id: 'bootcamp',
                  name: 'Bootcamp'
                }]"
                item-text="name"
                item-value="id"
                autocomplete="new-password"
                hide-details="auto"
                v-model="unit">
              </v-select>
            </v-col>
            <v-col v-show="unit == 'school'">
              <div>Organisasi</div>
              <v-row v-for="(item, index) in organizations" :key="index">
                <v-col cols="3">
                  <!-- <ValidationProvider ref="organization" name="Organisasi" rules="required" v-slot="{ errors }"> -->
                    <v-autocomplete 
                      label="Pilih Jenjang"
                      outlined  
                      color="primary" 
                      :items="grades"
                      item-text="name"
                      item-value="id"
                      hide-details="auto" 
                      return-object
                      @change="fetchOrganization(item.grade.id)"
                      v-model="item.grade">
                    </v-autocomplete>
                  <!-- </ValidationProvider> -->
                </v-col>
                <v-col cols="5">
                  <!-- <ValidationProvider ref="organization" name="Organisasi" rules="required" v-slot="{ errors }"> -->
                    <v-autocomplete 
                      label="Pilih Organisasi"
                      outlined  
                      color="primary" 
                      search-input.sync=""
                      :items="organization"
                      item-text="name"
                      item-value="id"
                      hide-details="auto" 
                      return-object
                      @change="fetchMajor(item.grade.id, item.organization.id)"
                      v-model="item.organization">
                    </v-autocomplete>
                  <!-- </ValidationProvider> -->
                </v-col>
                <v-col cols="3">
                  <!-- <ValidationProvider ref="organization" name="Organisasi" rules="required" v-slot="{ errors }"> -->
                    <v-autocomplete 
                      label="Pilih Jurusan"
                      outlined  
                      color="primary" 
                      :items="majors"
                      item-text="name"
                      item-value="id"
                      hide-details="auto" 
                      return-object
                      v-model="item.major">
                    </v-autocomplete>
                  <!-- </ValidationProvider> -->
                </v-col>
                <v-col cols="1">
                  <v-btn 
                    small
                    icon
                    color="red"
                    class="mt-1"
                    @click="removeOrganizations(index)">
                    <v-icon>ri-delete-bin-6-line</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-show="unit == 'school'">
              <v-btn 
                small
                color="primary"
                block
                @click="addOrganization()">
                Tambah Organisasi
              </v-btn>
            </v-col>
            <!-- <v-col>
              <p>Trainer Description</p>
              <v-textarea
                outlined
                flat
                v-model="form.description"
                placeholder="Tuliskan disini"
                rows="3"
                color="primary"
              ></v-textarea>
            </v-col> -->
            <v-col>
              <p class="mb-0">Gender</p>
              <v-radio-group row class="mt-2" hide-details="auto" v-model="form.gender.value">
                <v-radio color="primary" :value="1">
                  <template v-slot:label>
                    <div class="black--text">Laki - laki</div>
                  </template>
                </v-radio>
                <v-radio color="primary" :value="0">
                  <template v-slot:label>
                    <div class="black--text">Perempuan</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <p class="mb-0">Trainer Status</p>
              <v-radio-group row class="mt-2" hide-details="auto" v-model="form.status">
                <v-radio color="primary" value="active">
                  <template v-slot:label>
                    <div class="black--text">Aktif</div>
                  </template>
                </v-radio>
                <v-radio color="primary" value="inactive">
                  <template v-slot:label>
                    <div class="black--text">Tidak aktif</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col>
              <p>Program Trainer</p>


              <div v-if="programs.length > 0">
                <div v-for="(prog, i) in programs" :key="i">
                  <div class="d-flex py-2">
                    <v-icon
                      @click="deleteProgramTrainer(prog, i)"
                      color="red">
                      ri-delete-bin-line
                    </v-icon>
                    <v-card outlined class="pa-2 ml-3" style="flex: 1">
                      {{ prog.name }} {{ i+1 }}
                    </v-card>
                  </div>
                </div>
              </div>

              <div v-else class="">
                <v-alert type="warning" dense outlined class="subtitle-2">
                  Belum ada program yang ditambahkan pada trainer ini.
                </v-alert>
              </div>


            </v-col> -->
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pb-4 px-4">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          width="120"
          height="40"
          color="grey lighten-3"
          class="text-capitalize"
          :loading="process.run"
          :to="`/trainer`"
        >
          Batal
        </v-btn>
        <v-btn
          depressed
          width="120"
          height="40"
          color="primary"
          class="text-capitalize"
          :loading="process.run"
          @click="save()"
        >
          Simpan
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" outlined :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { URL_API } from "@/constants/api";

export default {
  data() {
    return {

      programOptions: ["Program 1 ", "Program 2 ", "Program 3 ", "Program 4 "],
      breadcrumbs: [
        {
          text: "List Trainer",
          disabled: false,
          href: `/company/trainer-list/${this.$route.params.company_id}`,
        },
        {
          text: "Update Trainer",
          disabled: true,
          href: "#",
        },
      ],
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },
      programs: [],
      organization: [],
      grades: [],
      majors: [],

      items: {},
      member: {},

      organizations: [],
      unit: "",

      form: {
        unit: "",
        meta: {
          organization_id: ""
        }
      },
      dialog: {
        attachment: false,
      },
      process: {
        run: false,
        state: "",
      },
    };
  },
  watch: {
    search_organization: function () {
      this.fetchOrganization()
    },
    search_grade: function () {
      this.fetchGrade()
    },
    search_major: function () {
      this.fetchMajor()
    },
  },
  created() {
    this.getDetail();
    
    // this.fetchOrganization();
    this.fetchGrade()
    // this.fetchMajor()
  },
  mounted() {
    let title=  "Update Trainer"
    this.$emit("page-changed", 1, {
      title,
      link: '/trainer',
    });
  },
  methods: {
    addOrganization() {
      this.organizations.push({
        grade: {
          id: "",
          name: ""
        },
        organization: {
          id: "",
          name: ""
        },
        major: {
          id: "",
          major: "",
        }
      })
    },

    removeOrganizations(index) {
      this.organizations.splice(index, 1)
    },

    removeProgram(index) {
      this.form.programs.splice(index, 1);
    },
    deleteProgramTrainer(item, index) {
      this.programs.splice(index, 1)
    },
    
    async fetchGrade() {
      await this.axios.get(`${URL_API.ORGANIZATION.GRADE.LIST}`, {
        params: {
          // name: this.search_grade,
          limit: 50
        }
      })
      .then((response) => {
        let res = response.data
        this.grades = res.data.list
      }).catch((error) => {
        console.log(error);
      })
    },
    async fetchOrganization(grade_id) {
      await this.axios.get(`${URL_API.ORGANIZATION.LIST}`, {
        params: {
          // name: this.search_organization,
          organization_grade_id: grade_id,
          limit: 10000
        }
      })
      .then((response) => {
        let res = response.data
        this.organization = res.data.list
      }).catch((error) => {
        console.log(error);
      })
    },
    async fetchMajor() {
      await this.axios.get(`${URL_API.ORGANIZATION.MAJOR.LIST}`, {
        params: {
          // name: this.search_major,
          // organization_grade_id: grade_id,
          // organization_id: organization_id,
          limit: 100
        }
      })
      .then((response) => {
        let res = response.data
        this.majors = res.data.list
      }).catch((error) => {
        console.log(error);
      })
    },

    async getDetail() {
      let _self = this;
      _self.process.run = true;

      await this.axios.get(`${URL_API.TRAINER.DETAIL(this.$route.params.id)}`).then(
        (response) => {
          let res = response.data;
          let dataDetail = res.data
          dataDetail.status = dataDetail.status == '' ? 'inactive' : dataDetail.status
          this.form = dataDetail;
          // this.form.unit = dataDetail.organization.unit
          this.unit = dataDetail.organization.unit
          this.organizations = dataDetail.organization.organizations !== null ? dataDetail.organization.organizations : []

          if(dataDetail.organization.organizations != null && dataDetail.organization.organizations.length > 0) {
            dataDetail.organization.organizations.map((item) => {
              this.fetchOrganization(item.grade.id)
              // this.fetchMajor(item.grade.id, item.organization.id)
              this.fetchMajor()
            })
          }

          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
    async save() {

      const isValid = await this.$refs.observer.validate()

      // const isValidateOrganization = this.organizations.map(item => {
      //   return item.grade.id && item.organization.id && item.major.id
      // })

      if(isValid) {

        let organizations = []

        if(this.unit == 'school') {
          organizations = this.organizations.map(item => {
            return {
              organization: {
                name: item.organization.name,
                id: item.organization.id
              },
              grade: {
                name: item.grade.name,
                id: item.grade.id
              },
              major: {
                name: item.major.name,
                id: item.major.id
              }
            }
          })
        }
        

        let data = {
          fullname: this.form.fullname,
          email: this.form.email,
          phone_number: this.form.phone_number,
          gender: this.form.gender.value,
          status: this.form.status,
          organization: {
            unit: this.unit,
            organizations
          }
        };
        this.process.run = true;
        await this.axios.put(`${URL_API.TRAINER.UPDATE(this.$route.params.id)}`, data)
          .then((response) => {
            let res = response.data;
            if (res.status) {
              this.snackbar = {
                state: true,
                text: `Update Trainer Succesfully`,
                color: "primary",
                timeout: 4000
              },
              setTimeout(() => {
                this.$router.push(`/trainer`,);
              }, 1000);
            } else {
              this.snackbar = {
                state: false,
                text: `Update Trainer Failed`,
                color: "red",
                timeout: 4000
              }
            }
            this.process.run = false;
          })
          .catch((e) => {
            this.process.run = false;
            this.snackbar = {
              state: false,
              text: e.response.data.message,
              color: "red",
              timeout: 4000
            }
          });
        // this.saveProgramOptions();
      }

    },
    data_attachment() {
      // console.log(data);
    },
    closeDialog () {
      this.dialog.attachment = false
    },
  },
};
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 300px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #1A56DB;
}

.dropZone:hover .dropZone-title {
  color: #1A56DB;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
