<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <v-navigation-drawer height="100vh" fixed floating v-model="drawer" :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="!$vuetify.breakpoint.mdAndUp" class="elevation-1">
        <v-spacer class="mt-16"></v-spacer>
        <v-list flat>
          <div v-for="(item, id) in items" :key="id">
            <can I="create" :a="item.permission">
              <v-list-item active-class="menu-active" :color="item.disabled ? 'color lighten-3' : 'primary'"
                v-if="item.children == undefined && !item.disabled" class="transparent" :key="item.title" :to="item.link"
                @click="pageChanged(0, item)">
                <!-- active-class="menu-active" -->
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-group v-else-if="!item.disabled" :prepend-icon="item.icon" active-class="menu-active">
                <template slot="activator">
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </template>

                <v-list-item class="background" color="primary" v-for="item in item.children" :key="item.title"
                  :to="item.link" @click="pageChanged(0, item)">
                  <v-list-item-icon>
                    <v-icon v-text="''"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </can>
          </div>
          <!-- <v-list-item
              class="py-1 pl-6"
              active-class="menu-active"
              to="/dashboard"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>$dashboard</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 color--text">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="">
              <v-list-group>
                <template v-slot:activator class="pl-2">
                  <v-list-item-icon class="mr-4">
                    <v-icon class="color--text"> $epot </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1 color--text"> Tes EPOT </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item to="/exam/greeting">
                  <v-list-item-icon class="mr-4">
                    
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 color--text"> Greeting </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/epot">
                  <v-list-item-icon class="mr-4">
                    
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 color--text"> Kelola Tes </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item> -->
        </v-list>
      </v-navigation-drawer>

      <!-- Topnav / App bar -->
      <v-main class="background" :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }">
        <v-app-bar color="background" :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }" flat fixed>
          <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="drawer = true"></v-app-bar-nav-icon>

          <v-toolbar-title>
            <h3 class="font-weight-regular">{{ pageTitle }}</h3>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <div v-if="false">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" class="mx-6" fab small elevation="1" v-bind="attrs" v-on="on">
                  <v-badge color="red" bordered :value="notifications.length" :content="notifications.length"
                    offset-y="12" dot overlap>
                    <v-icon>$notification</v-icon>
                  </v-badge>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item>
                  <v-list-item-title v-text="'Tidak ada notifikasi'"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider vertical class="my-auto" style="height: 40px; min-height: auto"></v-divider>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-6 rounded-pill" v-ripple v-bind="attrs" v-on="on">
                <v-avatar class="elevation-1 white mr-2" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium">{{ user.fullname }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <!-- <v-list-item link to="/profil">
                <v-list-item-title v-text="'Profil'"></v-list-item-title>
              </v-list-item>
              <v-list-item link to="/update-password">
                <v-list-item-title
                  v-text="'Update Password'"
                ></v-list-item-title>
              </v-list-item> -->
              <v-list-item link @click="logout">
                <v-list-item-title v-text="'Logout'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-container fluid style="margin-top: 64px">
          <div class="content-wrapper mx-2">
            <v-breadcrumbs class="px-0 pt-0" :items="breadcrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item>
                  <span v-if="item.disabled">
                    {{ item.text }}
                  </span>
                  <router-link class="text-decoration-none" v-else :to="item.href">
                    {{ item.text }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <transition name="fade-up">
              <router-view v-if="loaded" @page-changed="pageChanged"></router-view>
            </transition>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import menu from './menu';

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    items() { return menu.filter((item) => item.permission) }
  },
  created() {
    this.$root.$on("set-page-title", (/** @type {any} */ pageTitle) => {
      this.pageTitle = pageTitle;
    });
    // this.$root.$on("set-breadcrumbs", (breadcrumbs) => {
    //   console.log(this.breadcrumbs);
    //   console.log(breadcrumbs);
    //   this.breadcrumbs = breadcrumbs;
    // });

    let path = "/" + this.$route.path.split("/")[1];
    let index = this._.findIndex(this.items, { link: path });
    if (index != -1) {
      this.pageTitle = this.items[index].title;
      this.breadcrumbs.push({
        text: this.items[index].title,
        disabled: true,
        href: path,
      });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    /**
    * @param {{ title: any; }} item
    */
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    /**
    * @param {any} segment
    * @param {any} location
    */
    pageChanged(segment, location = null) {
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((/** @type {{ disabled: boolean; }} */ item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }

      document.title =
        this.breadcrumbs
          .slice()
          .reverse()
          .map((/** @type {{ text: any; }} */ val) => val.text)
          .join(" / ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>
