import { URL_API } from '../constants/api'

import axios from 'axios'
import url from './api'

const offline = false

const state = () => ({
  medias: []
})

const mutations = {
  create(state, payload) {
    state.medias.push(payload.data)
  },
}

const getters = {
  detail({ state }, id) {
    let data = state.medias.find(data => data.id == id)
    if (!data) {
      let datas = JSON.parse(localStorage.getItem('ytc_medias')) || []
      datas.find(data => data.id == id)
    }
    return data
  },

  list: () => (params) => {
    let offset = (params.page - 1) * params.page
    let data = JSON.parse(localStorage.getItem('ytc_medias')) || [];
    let dir = 'asc'

    if (params.type) data = data.filter(data => data.type == params.type)
    if (params.dir) dir = params.dir
    if (params.sort) data = data.sort(data => data[params.sort], dir)
    if (params.source) data = data.filter(data => data.source == params.type)
    if (params.title) data = data.filter(data => data.title.includes(params.title))

    return {
      limit: params.limit,
      list: data.slice().splice(offset, params.limit),
      page: params.page,
      total: data.length
    }
  }
}

const actions = {
  async list({ getters }, params) {
    return new Promise((resolve, reject) => {
      params = Object.assign({ page: 1, limit: 10 }, params)
      if (offline) {
        try {
          resolve(url.success(getters.list(params)))
        } catch (error) {
          reject(error)
        }
      } else {
        axios.get(URL_API.MEDIA.LIST, { params })
          .then(res => {
            // let data = JSON.parse(localStorage.getItem('ytc_medias'))
            // data.push(...res.data.data.list)
            // localStorage.setItem('ytc_medias', JSON.stringify(data))
            resolve(res.data)
          })
          .catch(error => { reject(error) })
      }
    })
  },

  async create({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (offline) {
        try {
          setTimeout(() => {
            data = {
              "id": url.get_id(),
              "title": "",
              "caption": "",
              "alt": "",
              "description": "",
              "type": "image",
            }
            commit('create', data)
            resolve(url.success(data))
          }, 2000);
        } catch (error) {
          reject(error)
        }
      } else {
        // let formData = new FormData()
        // Object.keys(data).forEach(key => { formData.append(key == 'public_url' ? 'url' : key, data[key] || "") })
        axios.post(URL_API.MEDIA.UPLOAD, data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(res => { resolve(res.data) })
          .catch(error => { reject(error) })
      }
    })
  },

  async detail({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (offline) {
        resolve(url.success(getters.detail(id)))
      } else {
        axios.get(URL_API.MEDIA.DETAIL(id))
          .then(res => { resolve(res.data) })
          .catch(error => { reject(error) })
      }
    })
  },

  async detail_public({ getters }, id) {
    return new Promise((resolve, reject) => {
      if (offline) {
        resolve(url.success(getters.detail(id)))
      } else {
        axios.get(URL_API.MEDIA.DETAIL_PUBLIC(id))
          .then(res => { resolve(res.data) })
          .catch(error => { reject(error) })
      }
    })
  }
}


export default { namespaced: true, state, mutations, actions, getters }