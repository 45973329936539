import { URL_API } from "../constants/api";

import axios from "axios";
// import url from "../store/api";
// const base_url = "http://prakerja.com/v1";

const SET_LIST = "SET_LIST";

function objectToFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] == undefined) {
      return;
    }
    if (!!object[key] && typeof object[key] == "object") {
      if (object[key] instanceof File) {
        formData.append(key, object[key], object[key].name);
      } else {
        for (let data of objectToFormData(object[key]).entries()) {
          const obindex = data[0].indexOf("[");
          const all = data[0].length;
          formData.append(`${key}[${data[0].slice(0, obindex == -1 ? all : obindex)}]${data[0].slice(obindex == -1 ? all : obindex)}`, data[1]);
        }
      }
    } else {
      const value = typeof object[key] == "boolean" ? (object[key] ? 1 : 0) : object[key];
      formData.append(key, value);
    }
  });
  return formData;
}

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, payload) => {
      const index = state.list.findIndex((v) => v.topic == payload.topic);
      if (index != -1) state.list.splice(index, 1);
      state.list.push(payload);
    },
  },
  actions: {
    async fetch(context, payload) {
      const response = await axios
        .get(`${URL_API.PROGRAM.ACTIVITY.LIST(payload.program, payload.topic )}`, { params: payload.params })
        .then((res) => res.data);
      context.commit(SET_LIST, { topic: payload.topic, data: response.data.list });
      return response;
    },
    async create(context, payload) {
      const formData = objectToFormData(payload);
      const config = payload.config || {}
      const response = await axios
        .post(`${URL_API.PROGRAM.ACTIVITY.CREATE(payload.program, payload.topic)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async show(context, payload) {
      return await axios.get(`${URL_API.PROGRAM.ACTIVITY.DETAIL(payload.program, payload.topic, payload.id)}`).then((res) => res.data);
    },
    async edit(context, payload) {
      const formData = objectToFormData(payload.data);
      const config = payload.config || {}
      const response = await axios
        .post(`${URL_API.PROGRAM.ACTIVITY.UPDATE(payload.program, payload.topic, payload.id)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async edit_quiz(context, payload) {
      const formData = payload.data;
      const config = payload.config || {}
      const response = await axios
        .put(`${URL_API.PROGRAM.ACTIVITY.UPDATE_QUIZ(payload.program, payload.topic, payload.id)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async reorder(context, payload) {
      const response = await axios
        .put(`${URL_API.PROGRAM.ACTIVITY.REORDER(payload.program, payload.topic)}`, payload.data)
        .then((res) => res.data);
      return response;
    },
    async approve(context, payload) {
      return await axios.put(`${URL_API.PROGRAM.ACTIVITY.APPROVE(payload.program, payload.topic, payload.id)}`).then((res) => res.data);
    },
    async delete(context, payload) {
      return await axios.delete(`${URL_API.PROGRAM.ACTIVITY.DELETE(payload.program, payload.topic, payload.id)}`).then((res) => res.data);
    },
  },
};
