import {  VAppBar, VBtn, VTextField, VRadioGroup } from "vuetify/lib";

VAppBar.extendOptions.props.color = { type: String, default: "transparent" };

// VDataTable.extendOptions.props.hideDefaultFooter = { type: Boolean, default: false };

VBtn.extendOptions.props.elevation = { type: [String, Number], default: 0 };

VTextField.options.props.outlined = { type: [Boolean, String], default: true };
VTextField.options.props.hideDetails = { type: [Boolean, String], default: 'auto' };
VTextField.options.props.color = { type: String, default: "primary-active" };
VTextField.options.props.backgroundColor = { type: String, default: "#F4F6F9" };

VRadioGroup.extendOptions.props.hideDetails = { type: [Boolean, String], default: 'auto' };

// import * as Vuetify from "vuetify/lib";
// console.log(Vuetify);

export default {
  theme: {
    themes: {
      light: {
        background: "#F5F7F9",
        color: "#4C6583",
        primary: "#1A56DB",
        secondary: "#9CAFC6",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: {
      // menu
      dashboard: "ri-dashboard-3-line",
      program: "ri-sticky-note-line",
      class: "ri-artboard-line",
      practice: "ri-honour-line",
      help: "ri-stack-line",
      user: "ri-user-line",
      certificate: "ri-red-packet-line",
      statistic: "ri-bar-chart-box-line",
      menu: "ri-menu-line",
      sort: "ri-arrow-down-line",

      // app-bar
      notification: "ri-notification-2-line",
      dropdown: "ri-arrow-down-s-line",

      //epot
      detail: "ri-todo-line",
      publish: "ri-upload-2-fill",
      archive: "ri-archive-line",
      draft: "ri-draft-line",
      arrdown: "ri-arrow-down-s-fill",
      search: "ri-search-line",
      music: "ri-music-fill",
      image: "ri-image-line",

      // action
      close: "ri-close-fill",
      // duplicate: 'ri-file-copy-2-line',
      complete: "ri-check-line",
      success: "ri-checkbox-circle-line",
      info: "ri-information-line",
      warning: "ri-error-warning-line",
      error: "ri-error-warning-fill",
      replace: "ri-arrow-go-back-line",
      edit: "ri-pencil-line",
      delete: "ri-delete-bin-6-line",
      play: "ri-play-fill",
      pause: "ri-pause-fill",
      refresh: "ri-refresh-line",

      checkboxOn: "ri-checkbox-fill",
      checkboxOff: "ri-checkbox-blank-line",
      chevronRight: "ri-arrow-right-s-line",
      // expand:'<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9H0V18H9V16H2V9ZM9 2H16V9H18V0H9V2Z" fill="#4C6583"/></svg>',
      radioOff: "ri-checkbox-blank-circle-line",
      radioOn: "ri-radio-button-line",
      next: "ri-arrow-right-s-line",
      prev: "ri-arrow-left-s-line",
      checkboxIndeterminate: "ri-checkbox-blank-line",
      expand: "ri-arrow-down-s-line",
      eye: "ri-eye-line",
      checkDouble: "ri-checkbox-multiple-line",
      print: "ri-printer-line",

      // action
      add: 'ri-add-line',
    },
  },
};
