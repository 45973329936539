const ADMIN = `admin`
const PUBLIC = `public`

const URL_API = {
  AUTH: {
    LOGIN: `${ADMIN}/auth/login`,
    LOGOUT: `${ADMIN}/auth/logout`,
  },
  COMPANY: {
    DETAIL: `${ADMIN}/company/v1/detail`,
  },
  PROGRAM: {
    LIST: `${ADMIN}/program/v1/company/list`,
    CREATE: `${ADMIN}/program/v1/company/create`,
    DETAIL: (id) => `${ADMIN}/program/v1/company/detail/${id}`,
    UPDATE: (id) => `${ADMIN}/program/v1/company/update/${id}`,
    DELETE: (id) => `${ADMIN}/program/v1/company/delete/${id}`,
    UPDATE_STATUS: (id) => `${ADMIN}/program/v1/company/update-status/${id}`,

    EXPORT_PROGRAM: `${ADMIN}/users/v1/export/member_redeem`,
    EXPORT_EPM: `${ADMIN}/users/v1/export/member_redeem_epm`,

    SYNC_PROGRAM: `${ADMIN}/program/v1/company/sync-member-redeem`,

    DUPLICATE_PROGRAM: `${ADMIN}/program/v1/company/duplicate-program`,

    CHECK_AVAILABLE_REPOST: (id) => `${ADMIN}/program/v1/company/check-available-prepost/${id}`,

    STUDY: {
      LIST: `${ADMIN}/study/v1/list`,
      CREATE: `${ADMIN}/study/v1/create`,
      DETAIL: (id) => `${ADMIN}/study/v1/detail/${id}`,
      UPDATE: (id) => `${ADMIN}/study/v1/update/${id}`,
    },

    TOPIC: {
      LIST: `${ADMIN}/topic/v1/list`,
      DETAIL: (id) => `${ADMIN}/topic/v1/detail/${id}`,
      CREATE: `${ADMIN}/topic/v1/create`,
      UPDATE: (id) => `${ADMIN}/topic/v1/update/${id}`,
      DELETE: (id) => `${ADMIN}/topic/v1/delete/${id}`,
      REORDER: `${ADMIN}/topic/v1/reorder`,
    },

    ACTIVITY: {
      LIST: (program, topic) => `${ADMIN}/v1/activity/${program}/${topic}/list`,
      CREATE: (program, topic) => `${ADMIN}/v1/activity/${program}/${topic}/create`,
      DETAIL: (program, topic, id) => `${ADMIN}/v1/activity/${program}/${topic}/${id}/show`,
      UPDATE: (program, topic, id) => `${ADMIN}/v1/activity/${program}/${topic}/${id}/update`,
      UPDATE_QUIZ: (program, topic, id) => `${ADMIN}/v1/activity/${program}/${topic}/${id}/update-quiz`,
      REORDER: (program, topic) => `${ADMIN}/v1/activity/${program}/${topic}/reorder`,
      APPROVE: (program, topic, id) => `${ADMIN}/v1/activity/${program}/${topic}/${id}/approve`,
      DELETE: (program, topic, id) => `${ADMIN}/v1/activity/${program}/${topic}/${id}/delete`,
    },

    GAMEPLAY: {
      LIST: `${ADMIN}/gameplay/v1/list`,
      CREATE: `${ADMIN}/gameplay/v1/create`,
      DETAIL: (id) => `${ADMIN}/gameplay/v1/detail/${id}`,
      DETAIL_MULTI: `${ADMIN}/gameplay/v1/detail-multiple`,
      UPDATE: (id) => `${ADMIN}/gameplay/v1/update/${id}`,
      DELETE: (id) => `${ADMIN}/gameplay/v1/delete/${id}`,
    },

    SECTION: {
      LIST: (activity) => `${ADMIN}/v1/section/${activity}/list`,
      CREATE: (activity) => `${ADMIN}/v1/section/${activity}/create`,
      DETAIL: (activity, id) => `${ADMIN}/v1/section/${activity}/${id}/show`,
      UPDATE: (activity, id) => `${ADMIN}/v1/section/${activity}/${id}/update`,
      APPROVE: (activity, id) => `${ADMIN}/v1/section/${activity}/${id}/approve`,
      DELETE: (activity, id) => `${ADMIN}/v1/section/${activity}/${id}/delete`,
    },

    PACKET: {
      LIST: (program, topic, activity) => `${ADMIN}/v1/packet/${program}/${topic}/${activity}/list`,
      CREATE: (program, topic, activity) => `${ADMIN}/v1/packet/${program}/${topic}/${activity}/create`,
      DETAIL: (program, topic, activity, id) => `${ADMIN}/v1/packet/${program}/${topic}/${activity}/${id}/show`,
      UPDATE: (program, topic, activity, id) => `${ADMIN}/v1/packet/${program}/${topic}/${activity}/${id}/update`,
      APPROVE: (program, topic, activity, id) => `${ADMIN}/v1/packet/${program}/${topic}/${activity}/${id}/approve`,
      DELETE: (program, topic, activity, id) => `${ADMIN}/v1/packet/${program}/${topic}/${activity}/${id}/delete`,
    },

    GROUP: {
      LIST: (program, topic, activity, packet) => `${ADMIN}/v1/group/${program}/${topic}/${activity}/${packet}/list`,
      CREATE: (program, topic, activity, packet) => `${ADMIN}/v1/group/${program}/${topic}/${activity}/${packet}/create`,
      DETAIL: (program, topic, activity, packet, id) => `${ADMIN}/v1/group/${program}/${topic}/${activity}/${packet}/${id}/show`,
      UPDATE: (program, topic, activity, packet, id) => `${ADMIN}/v1/group/${program}/${topic}/${activity}/${packet}/${id}/update`,
      APPROVE: (program, topic, activity, packet, id) => `${ADMIN}/v1/group/${program}/${topic}/${activity}/${packet}/${id}/approve`,
      DELETE: (program, topic, activity, packet, id) => `${ADMIN}/v1/group/${program}/${topic}/${activity}/${packet}/${id}/delete`,
    },

    BANK: {
      LIST: `${ADMIN}/bank/v1/list`,
      CREATE: `${ADMIN}/bank/v1/question/admin/create`,
      DETAIL: (id) => `${ADMIN}/bank/v1/question/admin/detail/${id}`,
      UPDATE: (id) => `${ADMIN}/bank/v1/question/admin/update/${id}`,
      DELETE: (id) => `${ADMIN}/bank/v1/delete/${id}`,
    },

  },
  MEDIA: {
    LIST: `${ADMIN}/storage/v1/media/company/list`,
    DETAIL: (id) => `${ADMIN}/storage/v1/media/company/detail/${id}`,
    DETAIL_PUBLIC: (id) => `${ADMIN}/storage/v1/media/company/public/${id}`,
    UPLOAD: `${ADMIN}/storage/v1/media/company/upload`,
  },
  USER: {
    LIST: (id) => `${ADMIN}/program/v1/company/list-member-redeem/${id}`,
    DETAIL: (id) => `${ADMIN}/users/v1/company/detail/${id}`,
    UPDATE: `${ADMIN}/users/v1/company/update`,

    DETAIL_MEMBER_REDEEM: (id) => `${ADMIN}/program/v1/company/detail-member-redeem/${id}`,
    EXPORT_MEMBER_REDEEM: (id) => `${ADMIN}users/v1/export/member_redeem/${id}`,
    UPDATE_BY_ID: (id) => `${ADMIN}/users/v1/member/update_by_id/${id}`,
    DETAIL_BY_ID: (id) => `${ADMIN}/users/v1/member/detail_by_id/${id}`,

    LIVENESS_STATUS: (member_id, topic_id) => `${ADMIN}/users/v1/prakerja/liveness-status/${member_id}/${topic_id}`,
    SUBMISSION_STATUS: (member_id, topic_id, activity_id) => `${ADMIN}/users/v1/prakerja/submission-status/${member_id}/${topic_id}/${activity_id}`,

    FEEDBACK: `${ADMIN}/users/v1/member/activity/feedback`,
  },
  NOTIFICATION: {
    LIST: `${ADMIN}/notification/v1/list`,
  },
  PRESENSI: {
    LIST: `${ADMIN}/presence/v1/company/list`,
    EXPORT: `${ADMIN}/presence/v1/company/export`,
  },
  RESELLER: {
    LIST: `${ADMIN}/reseller/v1/list`,
    LIST_USER: (id) => `${ADMIN}/reseller/v1/list-user-reseller/${id}`,
    CREATE: `${ADMIN}/reseller/v1/create`,
    UPDATE: (id) => `${ADMIN}/reseller/v1/update/${id}`,
    UPDATE_REDEEM_META: `${ADMIN}/users/v1/company/update_redeem_meta`,
    DETAIL: (id) => `${ADMIN}/reseller/v1/detail/${id}`,

    EXPORT_USER_RESELLER: `${ADMIN}/reseller/v1/export/list-user-reseller`,
  },
  PAGES: {
    PRIVACY_POLICY: {
      CREATE: `${ADMIN}/company/v1/create-or-update-page`,
      DETAIL: (slug) => `${ADMIN}/company/v1/detail-page/${slug}`,
    }
  },
  TRAINER: {
    LIST: `${ADMIN}/trainer/v1/company/list`,
    PROGRAM_LIST: `${ADMIN}/program/v1/trainer/list`,
    DETAIL: (id) => `${ADMIN}/trainer/v1/company/detail/${id}`,
    CREATE: `${ADMIN}/trainer/v1/company/create`,
    UPDATE: (id) => `${ADMIN}/trainer/v1/company/update/${id}`,
    DELETE: (id) => `${ADMIN}/trainer/v1/company/delete/${id}`,
    SOFT_DELETE: (id) => `${ADMIN}/trainer/v1/company/soft-delete/${id}`,
  },
  ORGANIZATION: {
    LIST: `${PUBLIC}/organization/list`,
    DETAIL: (id) => `${PUBLIC}/organization/detail/${id}`,
    GRADE: {
      LIST: `${PUBLIC}/organization-grade/list`,
      DETAIL: (id) => `${PUBLIC}/organization-grade/detail/${id}`,
    },
    MAJOR: {
      LIST: `${PUBLIC}/organization-major/list`,
      DETAIL: (id) => `${PUBLIC}/organization-major/detail/${id}`,
    }

  },
  MEMBER_REDEEM: {
    LIST: `${ADMIN}/users/v1/company/member-redeem/list`,
    DETAIL: (id) => `${ADMIN}/program/v1/company/detail-member-redeem/${id}`,
    UPDATE: `${ADMIN}/users/v1/member/redeem/update`,
    DELETE: (id) => `${ADMIN}/users/v1/member/redeem/delete/${id}`,
    EXPORT: `${ADMIN}/users/v1/export/member_redeem`,
  },
  IMPORT: {
    FEEDBACK: `${ADMIN}/users/v1/member/activity/import-feedback`,
    FEEDBACK_MULTIPLE: `${ADMIN}/users/v1/member/activity/import-feedback-multiple`,
  },
  REDEEM_LOG: {
    PRAKERJA_LOG: {
      LIST: `${ADMIN}/users/v1/company/list-prakerja-log`,
      EXPORT: `${ADMIN}/users/v1/company/export-prakerja-log`,
    }
  },
}

export { URL_API };