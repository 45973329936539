import { URL_API } from "../constants/api";

import axios from "axios";
// import url from "./api";
// const base_url = "http://prakerja.com/v1";

const SET_LIST = "SET_LIST";
const SET_QUESTION = "SET_QUESTION";

export default {
  namespaced: true,
  state: () => ({
    list: [],
    questions: []
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, payload) => {
      state.list.push(payload);
    },
    SET_QUESTION: (state, data) => (state.list = Array.isArray(data) ? data : []),
  },
  actions: {
    async fetch(context, payload) {
      const response = await axios
        .get(`${URL_API.PROGRAM.GAMEPLAY.LIST}`,  {params: payload.params})
        .then((res) => res.data);
      context.commit(SET_LIST, { data: response.data.list });
      return response;
    },
    async create(context, payload) {
      // const formData = objectToFormData(payload);
      const config = payload.config || {}
      const response = await axios
        .post(`${URL_API.PROGRAM.GAMEPLAY.CREATE}`, payload, config)
        .then((res) => res.data);
      return response;
    },
    async show(context, payload) {
      // console.log(payload);
      return await axios.get(`${URL_API.PROGRAM.GAMEPLAY.DETAIL(payload)}`).then((res) => res.data);
    },
    async show_multi(context, payload) {
      return await axios.get(`${URL_API.PROGRAM.GAMEPLAY.DETAIL_MULTI}`, {
        params: payload
      }).then((res) => res.data);
    },
    async show_multi_choice(context, payload) {
      const response =  await axios.get(`${URL_API.PROGRAM.GAMEPLAY.DETAIL_MULTI}`, {
        params: payload
      }).then((res) => res.data);

      context.commit(SET_QUESTION, { data: response.data });
      return response;
    },
    async edit(context, payload) {
      // const formData = objectToFormData(payload.data);
      const formData = payload.data;
      const config = payload.config || {}
      const response = await axios
        .put(`${URL_API.PROGRAM.GAMEPLAY.UPDATE(payload.id)}`, formData, config)
        .then((res) => res.data);
      return response;
    },
    async delete(context, payload) {
      return await axios.delete(`${URL_API.PROGRAM.GAMEPLAY.DELETE(payload)}`).then((res) => res.data);
    },
  },
}