<template>
  <v-row justify="center">
    
    <v-col v-if="!isPaketEmpty" cols="4" class="pt-5">
      <v-row class="flex-column" align-content="center">
        <v-col class="mx-auto">
          <v-img src="@/assets/images/data_empty.png" width="200" class="text-center mx-auto"/>
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada reseller</div>
        </v-col>
        <div class="subtitle body-1 text-center">
          klik tombol di bawah ini untuk menambahkan reseller baru
        </div>
        <v-col class="text-center">
          <v-btn color="primary" elevation="0" :to="{ name: 'create-reseller'}">
            <v-icon>ri-add-line</v-icon> Reseller baru
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row>
      <v-col id="search" lg="6" class="d-flex align-center">                  
        <v-text-field
          outlined
          append-icon="$search"
          class="pt-0 mt-0 mr-4"
          placeholder="Search"
          v-model="name"
          solo
          flat
          @keydown.enter="fetchPeserta"
        >
          <!-- <template v-slot:append v-if="name">
            <v-icon @click="name = ''">ri-close-line</v-icon>
          </template>    --> 
        </v-text-field>          
      </v-col>
      <v-col lg="6" class="d-flex align-end justify-end">
        <!-- <v-btn
          class="white--text"
          color="primary"
          @click="download"
        >
          <v-icon class="mr-2">ri-download-line </v-icon> Export
        </v-btn> -->
      </v-col>
    </v-row>
      <v-row>
        <v-col id="dt-program">          
          <notif-all
              :loading="table_loading"
              :data="data"
              @refresh-list="fetchPeserta"
              @change="change"
            />            
        </v-col>
      </v-row>
      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import { URL_API } from "@/constants/api";

import NotifAll from "./components/tableUser";
import { mapGetters} from 'vuex'
// import moment from "moment";


export default {
  name: "user-list",
  components: { NotifAll},
  data: () => ({
    table_loading: false,    
    loading:false,
    submitting:false,
    name:'',
    gift:'',  
    snackbar: {
        state: false,
        text: "",
      },
    timeout:5000,

  }),
  computed: {
    isPaketEmpty: () => true,
    // ...mapGetters("user", ["list", "page", "limit", "total"]),
    ...mapGetters({ data: "reseller/data", params: "reseller/params" }),
    // topics() {
    //   return this.topics || [];
    // },
  },
  mounted() {
    // this.$emit("page-changed", 0, { title: "Reseler", link: `/daftar-reseller` });
    this.$emit("page-changed", 1, {
      title: this.$route.params.id,
      link: this.$route.path,
    });
    this.fetchPeserta();    
  },
  created(){
  },
  methods: {
    fetchAll(){
      this.fetchPeserta();
    },
    async fetchPeserta() {
      this.table_loading = true;
      // let user = {
      //   id:this.$route.params.id,
      //   q: this.name_program
      // }
      // let q= this.name_program      
      this.$store.dispatch("reseller/listUser",{reseller_code:this.$route.params.id,q: this.name}).finally(() => {
        this.table_loading = false;
      });
      
    },
    fetchSearch(value) {
      this.table_loading = true;
      this.$store.dispatch("reseller/listUser",this.$route.params.id,{
        q: value,page:1}).finally(() => {
        // console.log('q hasil',this.name)      
        this.table_loading = false;
      });          
    },
    change(item){
      if (item.meta.gift == 'true' ) {
        this.gift="false";
      }else{
        this.gift="true";
      }
      // console.log('value asli',item.meta.gift);
      // console.log('event berubah',this.gift);

      const data ={
        member_redeem_id: item.id,
        field: 'gift',
        value: this.gift
      }
      this.axios
        .put(`${URL_API.RESELLER.UPDATE_REDEEM_META}`, data)
        .then(() =>{
          this.snackbar.text = 'Successfully change status gift';
          this.snackbar.state = true;        
          // window.location.reload(true);
          this.fetchPeserta()
        })
        .catch((error) => {
          console.error(error);
        });
    },
    forceFileDownload(url){
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.png') //or any other extension
      document.body.appendChild(link)
      link.click()
    },
    download(){
      this.submitting=true;

      this.axios
        .get(`${URL_API.RESELLER.EXPORT_USER_RESELLER}?reseller_code=${this.$route.params.id}&limit=-1`)
        .then((res) =>{
          this.submitting=false;          
          this.url_ = res.data.data.data.public_url; 
          // console.log('url',this.url_);         
          this.forceFileDownload(this.url_)
        })
        .catch((res) => {
          this.submitting=false;
          this.snackbar.text = res.response.data.message;
          this.snackbar.state = true; 
        });            
    },    
          
  },
  watch: {

  },
};
</script>