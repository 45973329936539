<template>
  <v-row>
    <template v-if="loading">
      <v-col cols="12" sm="6">
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </v-col>
    </template>
    <!-- <template v-else-if="error">
      <v-col>
        <div class="error--text">
          {{ error }}
          <v-btn x-small fab color="info" @click="fetchQuestion" elevation="0">
            <v-icon>$refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
    </template> -->
    <template v-else>
      <v-col cols="12" sm="6">
        <p class="mb-2">Tipe Soal</p>
        <v-select
          filled
          outlined
          :items="types"
          v-model="data.type"
          hide-details="auto"
        ></v-select>
          <!-- :disabled="isDisabled" -->
      </v-col>
      <v-col cols="12" md="12">
        <p class="mb-2">Pertanyaan</p>
        <vue-editor
          id="editor1"
          :disabled="isDisabled"
          :placeholder="isDisabled ? '' : 'Masukan pertanyaan'"
          :editor-toolbar="customToolbar"
          v-model="question.text"
          useCustomImageHandler
          @image-added="handleImageAdded"
        ></vue-editor>
        <!-- <vue-editor
          id="editor"
          useCustomImageHandler
          @image-added="handleImageAdded"
          v-model="question.text"
        >
        </vue-editor> -->
      </v-col> 
      <v-col v-if="this.$route.name === 'activity-edit'" cols="12" md="12">
        <div v-if="question.media !=''">          
          <p class="mb-2">Attachment Audio</p>
          <audio-attachment
            v-if="isMediaSet('audio')"
              :disabled="isDisabled"
              class="d-inline-block"
              :src="question.media[0]._id"
              @update="
                $set(question, 'media',[
                  ...$event.id,
                ])
              "
            />
            <span v-if="isDisabled && _.isEmpty(question.media)">
              {{ "No media" }}
            </span>
        </div>
        <div v-else>
          <p class="mb-2">Attachment Audio</p>
          <audio-attachment
            v-if="isMediaSet('audio')"
              :disabled="isDisabled"
              class="d-inline-block"
              :src="question.media"
              @update="
                $set(question, 'media',[
                  ...$event.id,
                ])
              "
            />
            <span v-if="isDisabled && _.isEmpty(question.media)">
              {{ "No media" }}
            </span>
        </div>
          <!-- {{ question.media._id}} -->
      </v-col> 
      <v-col v-else cols="12" md="12">
        <p class="mb-2">Attachment Audio</p>
        <audio-attachment
          v-if="isMediaSet('audio')"
            :disabled="isDisabled"
            class="d-inline-block"
            :src="question.media"
            @update="
              $set(question, 'media',[
                ...$event.id,
              ])
            "
          />
          <span v-if="isDisabled && _.isEmpty(question.media)">
            {{ "No media" }}
          </span>
          <!-- {{ question.media._id}} -->
      </v-col>    
      <v-col cols="12" md="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="12">
        <div class="row">
          <p class="col-md-6 mb-2">Opsi Jawaban</p>
          <p class="col-md-6 mb-2" style="text-align: right">Jawaban yang benar</p>
        </div>
        <question-options :disabled="isDisabled" v-model="options" />
      </v-col>
      <v-col v-if="data.type ==='multiple_choice'" cols="12" sm="6">
        <p class="mb-2">Poin</p>
        <v-text-field
          :disabled="isDisabled"
          class="mb-2"
          outlined
          filled
          color="primary"
          v-model.number="data.point"
          hide-details="auto"
          placeholder="0"
          :rules="poinRules">
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <p class="mb-2">Timer (detik)</p>
        <v-text-field
          :disabled="isDisabled"
          class="mb-2"
          outlined
          filled
          color="primary"
          v-model="duration"
          hide-details="auto"
          placeholder="00"
          :rules="timerRules">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="12">
        <p class="mb-2">Pembahasan</p>
        <vue-editor
          id="editor2"
          :placeholder="isDisabled ? '' : 'Masukan pembahasan'"
          :disabled="isDisabled"
          :editor-toolbar="customToolbar"
          v-model="explanation.correct.text"
          useCustomImageHandler
          @image-added="handleImageAddedExplan"
        ></vue-editor>
      </v-col>
    </template>
  </v-row>
</template>

<script>
// import AnswerType from "../../_inc/options/AnswerType.vue";
import { VueEditor } from "vue2-editor";
import AudioAttachment from "../../../_inc/modal/AudioAttachment.vue";
// import axios from "axios";
// import Editor from '@tinymce/tinymce-vue'
import QuestionOptions from "./ExamOptions";
import model from "../_mixins/model";
import { mapGetters } from "vuex";


export default {
  // mixins: [ValidationMixins],
  mixins: [model],
  components: {
    // AnswerType,
    // 'editor':Editor,
    AudioAttachment,
    VueEditor,
    QuestionOptions,
  },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      error: "",
      question: {
        text:'',
        media:[],
      },
      options: {},
      duration: 0,
      explanation: { correct: {}, wrong: {} },
      types: [{ text: "Multiple Choice", value: "multiple_choice" },{ text: "Single Choice Survey", value: "single_choice_survey" }],
      poinRules: [(v) => v >= 0 || /.+,.+\..+/.test(v) || "Poin harus angka"],
      timerRules: [(v) => v >= 0 || /.+,.+\..+/.test(v) || "Timer harus angka",],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean","image"], // remove formatting button
      ],
      htmlForEditor:''
    };
  },
  computed: {
    ...mapGetters(["user"]),
    isDisabled() {
      return !!(this.disabled || this.loading);
    },

  },
  mounted() {
    // console.log('admin',this.options)
  },
  watch: {
    question: {
      handler(val) {
        this.$set(this.data, "question", val);
      },
      deep: true,
    },
    // audio: {
    //   handler(val) {
    //     this.$set(this.data, "question", val.media);
    //   },
    //   deep: true,
    // },
    options: {
      handler(val) {
        this.$set(this.data, "options", val.options);
        this.$set(this.data, "correct_answer", val.correct_answer);
      },
      deep: true,
    },
    duration: {
      handler(val) {
        val = parseInt(val);
        this.$set(this.data, "timer", { ...this.data.timer, duration: val, active: val > 0 });
      },
      deep: true,
    },
    explanation: {
      handler(val) {
        this.$set(this.data, "explanation", { ...this.data.explanation, ...val });
      },
      deep: true,
    },
  },
  methods: {
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      // formData.append("image", file);

      formData.append("file", file);
      formData.append("source", "upload");
      formData.append("title", "image question");
      // console.log('result',formData)

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          // console.log('data image',res)
          if (res.status == 200) {
            const url= res.data.public_url;
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          }
        })
        .catch(() => {
          // console.log("error", error);
        });

    },
    handleImageAddedExplan: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

            // Editor.insertEmbed(cursorLocation, "image", 'https://storage.googleapis.com/yec-cdn/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1632810917215958245-asset-4at2x.png');
            // resetUploader();


      var formData = new FormData();
      // formData.append("image", file);

      formData.append("file", file);
      formData.append("source", "upload");
      formData.append("title", "image explanation");
      // console.log('result',formData)

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          // console.log('data image',res)
          if (res.status == 200) {
            const url= res.data.public_url;
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          }
        })
        .catch(() => {
          // console.log("error", error);
        });

    },

    isMediaSet(type) {
      // console.log('audio',this.question)

      let disabled = this.disabled;
      let notset =
        this.question.media && this.question.media.url;
      let isset =
        (this.question.media && this.question.media.url) &&
        (this.question.media && this.question.media.type == type)
      return !disabled && !notset || isset;
      // return (
      //   !this.isDisabled &&
      //   !(this.question.question.with_media || this.question.question.media.url) ||
      //   ((this.question.question.with_media || this.question.question.media.url) &&

      // );
    },
  },
  created() {
    this.question = this.value?.question || {};
    this.question.media = this.value?.question.media || {};
    this.$set(this.options, "options", this.value?.options || []);
    this.$set(this.options, "correct_answer", this.value?.correct_answer || []);
    this.explanation = this.value?.explanation || {};
    this.duration = this.value?.timer?.duration || 0;
  },
};
</script>
