<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-bold">Pengaturan</div>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium">
        <span> Estimasi Durasi (Menit)</span>
      </div>
      <v-row no-gutters>
        <v-col cols="12" md="7">
          <v-row>
            <v-col>
              <v-text-field v-model="data.estimated_duration" type="number" min="0" placeholder="0"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-col cols="12" class="px-0 py-0">
        <div class="font-weight-bold">Durasi Pengumpulan</div>
        <div class="font-weight-normal subtitle-2 grey--text mb-3">Pengaturan durasi button Pilih Berkas dalam format hari</div>
        <v-text-field
          placeholder="Silahkan isikan berapa hari durasi pengumpulan berkas EPM di atur"
          v-model.number="data.max_upload_duration_evaluasi_praktek_mandiri">
        </v-text-field>
        <div class="primary--text" style="font-size: 10px;">*Default pengumpulan EPM 7 hari setelah mengakses Praktek Mandiri </div>
      </v-col>
    </v-col>

    <!-- Anti curang -->
    <v-col cols="12">
      <v-row>
        <v-col cols="12"><v-divider /></v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Capture Wajah
            </div>
            <div
              v-if="data.face_capture"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan capture wajah pada awal & akhir aktivitas</div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-switch v-model="data.face_capture" inset> </v-switch>
        </v-col>

        <v-col cols="12"><v-divider /></v-col>

        <v-col cols="12">
          <div class="font-weight-medium mb-2">Pengaturan kecurangan</div>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Open Tab
            </div>
            <div
              v-if="collect_data.open_new_tab"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan indentifikasi open tab peserta</div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch v-model="collect_data.open_new_tab" inset> </v-switch>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Deteksi wajah ganda
            </div>
            <div
              v-if="collect_data.multi_face_detection"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan identifikasi wajah peserta test</div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch v-model="collect_data.multi_face_detection" inset>
          </v-switch>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Suara
            </div>
            <div
              v-if="collect_data.chat_detection"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan Identifikasi suara percakapan</div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch v-model="collect_data.chat_detection" inset> </v-switch>
        </v-col>

        <!-- FITUR GUGUR TEST -->
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12"> Pengaturan Sistem Gugur untuk Peserta </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Sistem gugur open tab
            </div>
            <div
              v-if="disqualification.open_new_tab.enabled"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan sistem gugur open tab untuk peserta</div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch
            :disabled="!collect_data.open_new_tab"
            v-model="disqualification.open_new_tab.enabled"
            inset
          >
          </v-switch>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Sistem gugur deteksi wajah
            </div>
            <div
              v-if="disqualification.multi_face_detection.enabled"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan sistem gugur deteksi wajah peserta</div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch
            v-model="disqualification.multi_face_detection.enabled"
            :disabled="!collect_data.multi_face_detection"
            inset
          >
          </v-switch>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div style="margin-right: 10px; font-weight: 500; font-size: 16px">
              Sistem Gugur Suara
            </div>
            <div
              v-if="disqualification.chat_detection.enabled"
              style="color: #f05326; font-size: 12px"
            >
              Mode Aktif
            </div>
            <div v-else style="color: #8492a6; font-size: 12px">Non aktif</div>
          </div>
          <div>Pengaturan sistem gugur suara peserta</div>
        </v-col>

        <v-col cols="12" sm="6">
          <v-switch
            :disabled="!collect_data.chat_detection"
            v-model="disqualification.chat_detection.enabled"
            inset
          >
          </v-switch>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</template>

<script>
import model from "../_mixins/model";
export default {
  mixins: [model],
  data() {
    return {
      collect_data: {
        multi_face_detection: false,
        open_new_tab: false,
        chat_detection: false,
      },
      disqualification: {
        multi_face_detection: {
          enabled: false,
          max_violation: 3,
        },
        open_new_tab: {
          enabled: false,
          max_violation: 3,
        },
        chat_detection: {
          enabled: false,
          max_violation: 3,
        },
      },
      setting: {
        identification: {
          face: true,
          voice: true,
          new_tab: true,
        },
        failed: {
          face: true,
          voice: true,
          new_tab: true,
        },
      },
    };
  },
  created() {
    if (this.data.disqualification) this.disqualification = this.data.disqualification;
    if (this.data.collect_data) this.collect_data = this.data.collect_data;
  },
};
</script>