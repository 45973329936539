<template>
  <div>
    <v-row>
			<v-col id="search" lg="6" class="d-flex align-center">          				
				<v-text-field
					outlined
					append-icon="$search"
					class="pt-0 mt-0 mr-4"
					placeholder="Search"
					v-model="searching"
					solo
					flat
					@keydown.enter="fetchRedeemLog"
				>
					<template v-slot:append v-if="searching">
						<v-icon @click="searching = ''">ri-close-line</v-icon>
					</template>    
				</v-text-field>          
			</v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="filterExportData"
        >
          <v-icon left>ri-file-excel-line</v-icon>
          <span>Export</span>
        </v-btn>
      </v-col>
		</v-row>
    <v-row>
      <v-col id="dt_program">
        <table-list
          :loading="loading"
          :data="redeemLogs"
          @change="change"
          @detail="detail"
          @sorting="sorting"
          @voucher="voucher"
          @refresh-list="fetchRedeemLog"
        />
      </v-col>
    </v-row>

    <!-- DIALOG FILTER EXPORT -->
    <dialog-export
      v-model="dialogExport"
      :filter="filter"
      :loadingExport="loadingExport"
      @close="closeFilterExport"
      @export="exportData"
    />

    <!-- DIALOG STATUS PRAKERJA -->
    <v-dialog v-model="dialogStatusPrakerja" max-width="1300px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span>Detail Status Prakerja</span>
        </v-card-title>
        <v-card-subtitle class="pt-2">
          Di redeem pada <span class="font-weight-bold">{{ convertCreatedAt(redeemLogsDetail.CreatedAt) }}</span>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text style="height: 550px">
          <v-row>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Member</div>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="px-0">Nama Member</th>
                      <td>{{ redeemLogsDetail.Member.fullname }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Email</th>
                      <td>{{ redeemLogsDetail.Member.email }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">No. Handphone</th>
                      <td>{{ redeemLogsDetail.Member.phone }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Reference</div>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="px-0">Kode License</th>
                      <td>{{ redeemLogsDetail.Reference.UniqueID }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Sequence</th>
                      <td>{{ redeemLogsDetail.Reference.Sequence }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">From</th>
                      <td>{{ redeemLogsDetail.Reference.From }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Prakerja Request</div>
              <v-divider/>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="px-0">Kode Redeem</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.Request.Body).redeem_code }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Email</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.Request.Body).email }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Sequence</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.Request.Body).sequence }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">URI Redirect</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.Request.Body).redirect_uri }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Prakerja Response</div>
              <v-divider/>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(item, i) in redeemLogsDetail.Response" :key="i">
                      <th class="px-0 text-capitalize">{{ item.Key }}</th>
                      <td v-if="typeof item.Value == 'object'">
                        <div v-for="(value) in item.Value" :key="value.key">
                          <div v-if="typeof value.Value == 'object'">
                            <div v-for="(val) in value.Value" :key="val.key">
                              <span class="font-weight-bold">{{ value.Key }} {{ val.Key }}</span> : {{ val.Value }}
                            </div>
                          </div>
                          <div v-else>
                            <span class="font-weight-bold">{{ value.Key }}</span> : {{ value.Value }}
                          </div>
                        </div>
                      </td>
                      <td v-else>
                        {{ item.Value }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="primary"
            small
            elevation="0"
            :loading="loading"
            @click="redeemLogsDetail = {} ; dialogStatusPrakerja = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR NOTIFICATION -->
    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { URL_API } from "@/constants/api";

import TableList from "@/views/redeem-log/components/table-list.vue";
import DialogExport from "@/views/redeem-log/components/dialog-export.vue";
import moment from "moment";
export default {
  components: {
    'table-list': TableList,
    'dialog-export': DialogExport
  },
  data:() => ({
    loading: false,
    loadingExport: false,
    dialogStatusPrakerja: false,
    dialogExport: false,
    filter: {
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
    },
    snackbar: {
      state: false,
      text: "",
      timeout: 5000,
    },
    searching: "",
    redeemLogs: {},
    redeemLogsDetail: {},
  }),
  watch: {
    searching: {
      handler() {
        this.fetchRedeemLog();
      },
      deep: true,
    },
  },
  created() {
    this.$emit("page-changed", 0, {
			title: "Log Redeem",
			link: this.$route.path,
		});
    this.fetchRedeemLog();
  },
  computed: {
    convertCreatedAt() {
      return (date) => {
        return moment(date).locale('id').format("DD MMMM YYYY HH:mm:ss");
      };
    }
  },
  methods: {
    async fetchRedeemLog() {
      this.loading = true;
      await this.axios
        .get(`${URL_API.REDEEM_LOG.PRAKERJA_LOG.LIST}`, {
          params: {
            page: this.redeemLogs.page,
            limit: this.redeemLogs.limit,
            sort: this.redeemLogs.sort,
            q: this.searching,
          },
        })
        .then((res) => {
          this.redeemLogs = res.data.data;
          this.redeemLogs.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    change(data) {
      this.redeemLogs.page = data;
    },
    parseDataRequestBody(dataBody) {
      return JSON.parse(dataBody);
    },
    detail(data) {
      this.redeemLogsDetail = data;
      this.dialogStatusPrakerja = true;
    },
    sorting(data) {
      this.redeemLogs.sortDesc = data;
    },
    voucher(data) {
      this.$router.push({ name: "voucher-detail", params: { id: data } });
    },
    filterExportData() {
      this.dialogExport = true;
    },
    closeFilterExport() {
      this.dialogExport = false;
      this.filter = {
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
      };
    },
    async exportData() {
      this.loadingExport = true;

      await this.axios
        .get(`${URL_API.REDEEM_LOG.PRAKERJA_LOG.EXPORT}`, {
          params: {
            limit: -1,
            q: this.searching,
            start_date: `${this.filter.start_date}` + ` ${this.filter.start_time ? this.filter.start_time : ''}`,
            end_date: `${this.filter.end_date}` + ` ${this.filter.end_time ? this.filter.end_time : ''}`,
            range_date_type: 'created_at',
          }
        })
        .then((res) => {
          // const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = res.data.data.public_url;
          link.setAttribute("download", `${res.data.data.title}`);
          document.body.appendChild(link);
          link.click();
          this.loadingExport = false
          this.snackbar = {
            state: true,
            text: res.data.message,
          }
        })
        .catch((err) => {
          this.loadingExport = false;
          this.snackbar = {
            state: true,
            text: err.response.data.message,
          }
        });
    },
  }
}
</script>