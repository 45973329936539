<template>
  <v-card elevation="0" rounded="lg" outlined>
    <v-card-text>
      <v-form
        ref="form"
        class=""
        v-model="valid"
      >
        <h3 class="ps-2">Content Management Certificate Company</h3>
        <v-col cols="12">
          <div class="mb-2">Nama Lengkap</div>
          <v-text-field required filled hide-details="auto" v-model="title" placeholder="Masukan Nama Lengkap" :rules="[...rules('Nama Lengkap', 'required'), validate]" class="border-0"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">Domisili</div>
          <v-text-field required filled hide-details="auto" v-model="domisili" placeholder="Masukan Domisili" :rules="[...rules('Domisili', 'required'), validate]" class="border-0"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">No WA</div>
          <v-text-field required filled hide-details="auto" v-model="no_wa" placeholder="Masukan No WA" :rules="[...rules('No WA', 'required'), validate]" class="border-0" hint="Harap mengganti awalan nomor dengan angka 62. Cth:62897654321" persistent-hint></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">Provider Komisi</div>
          <v-text-field required filled hide-details="auto" v-model="komisi" placeholder="Masukan Provider Komisi" :rules="[...rules('Provider Komisi', 'required'), validate]" class="border-0"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">No Handphone</div>
          <v-text-field required filled hide-details="auto" v-model="no_hp" placeholder="Masukan No Handphone" :rules="[...rules('No Handphone', 'required'), validate]" class="border-0"></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">Email</div>
          <v-text-field
            class=""
            v-model="email"
            placeholder="Masukkan Email"
            :rules="emailRules"
            required
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="mb-2">Password</div>
          <v-text-field
            filled
            v-model="password"
            placeholder="Masukkan password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
            @click:append="show = !show"
            outlined
            hide-details="auto"
            :rules="rules('Password', 'required')"
          ></v-text-field>          
        </v-col>
      </v-form>
    </v-card-text>

    <v-card-actions class="pa-4" style="background: #f8f9fa">
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="submitting" elevation="0" @click="save">
        Simpan
      </v-btn>
    </v-card-actions>
    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="timeout"
    >
      <!-- :timeout="timeout" -->
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { URL_API } from "@/constants/api";

import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],  
  name: "reseller-add",
  data: () => ({
    tabSection: 0,
    valid: true,
    error:null,
    show:false,
    submitting:false,

    title:"",
    domisili:'',
    no_wa:'',
    no_hp:'',
    komisi:'',
    email:'',
    password:'',

    emailRules: [
        v => !!v || 'E-mail tidak boleh kosong',
        v => /.+@.+\..+/.test(v) || 'E-mail tidak valid',
      ],

    timeout:5000,    
    snackbar: {
      state: false,
      text: "",
    },
    
  }),
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  mounted() {
    let title =
      this.$route.name == "create-reseller" ? "Tambah Reseller" : "Edit Program";
    this.$emit("page-changed", 2, {
      title,
      link: this.$route.path,
    });
  },
  methods: {
    save() {
      this.errors = null;
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submitting=true;
          const data = {
            fullname: this.title,
            phone: this.no_hp,
            email: this.email,
            password:this.password,
            status: "active",
            meta:{
              gift:"false",
              domicile: this.domisili,
              whatsapp_number:this.no_wa,
              commission_provider:this.komisi,
            }
          }
            this.axios
              .post(`${URL_API.RESELLER.CREATE}`, data)
              .then(() =>{
                this.submitting=false;
                this.snackbar.text = `Successfully added data reseller`;
                this.snackbar.state = true;   
                setTimeout(() => {this.$router.push({name: "daftar-reseller"});},3000);                           
              })
              .catch((res) => {
                this.submitting=false;          
                if (res.response) {
                  this.snackbar.text = res.response.data.message;
                  this.snackbar.state = true;
                }
              });
        }
        else{
          this.snackbar.text = "Data cannot be empty, Please check your data";
          this.snackbar.state = true;
        }
      }, 50);
    }
  },
};
</script>