<template>
  <v-row>
    <v-col cols="12">
      <v-alert type="warning" text outlined>
        Format file yang didukung adalah mp4 ukuran maks: 100 MB.
      </v-alert>
    </v-col>
    <v-col cols="12">

      <!-- {{ data.file_variants }}
      <pre>{{ file_variants }}</pre> -->
      <!-- <div class="mb-2"></div>
      <div class="helper"></div> -->
      <v-row>
        <v-col cols="4">
          <div class="body-1 font-weight-medium mb-2">Kualitas Video 720p (Original)</div>
          <VideoInput v-model="variant1" :disabledForm="false" :indexFile="0" resolution="original" @input_file="updateTheoryData" @delete_file="deleteFileVideo" />
        </v-col>
        <v-col cols="4">
          <div class="body-1 font-weight-medium mb-2">Kualitas Video 480p</div>
          <!-- v-model="data.file_variants[1]" -->
          <VideoInput v-model="variant2" :disabledForm="file_variants.length < 1" :indexFile="1" resolution="480" @input_file="updateTheoryData" @delete_file="deleteFileVideo" />
        </v-col>
        <v-col cols="4">
          <div class="body-1 font-weight-medium mb-2">Kualitas Video 240p</div>
          <VideoInput v-model="variant3" :disabledForm="file_variants.length < 2" :indexFile="2" resolution="240" @input_file="updateTheoryData" @delete_file="deleteFileVideo" />
          <!-- <div
            class="drop display-inline text-center"
            @dragover.prevent
            @drop="onDrop2"
          >
            <input
              hidden
              ref="file"
              type="file"
              @change="onChange"
              accept="video/mp4"
            />
            <div v-if="file && file.type" class="fill-height pa-4">
              <video :src="file.url" width="300" height="240" controls></video>
              <div class="text-right">
                <v-btn small fab color="white" @click="$refs.file.click()">
                  <v-icon dark> ri-arrow-go-back-line </v-icon>
                </v-btn>
                <v-btn small fab color="white" @click="$delete(data, 'file')">
                  <v-icon dark> ri-delete-bin-7-line </v-icon>
                </v-btn>
              </div>
            </div>
            <div
              v-else
              class="hidden display-inline align-center py-4"
              v-bind:class="{ image2: true }"
            >
              <p class="mb-2 pt-8">
                <v-icon large>ri-video-fill</v-icon>
              </p>
              <p class="mb-0 subtitle-2">Tarik dan letakan video di sini</p>
              <p class="mb-2 subtitle-2">atau</p>
              <label class="btn-img display-inline text-center">
                Pilih Video
                <input
                  type="file"
                  name="image2"
                  @change="onChange2"
                  accept="video/mp4"
                />
              </label>
            </div>
          </div> -->
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Deskripsi Konten</span>
      </div>
      <!-- :rules="[...rules('Deskripsi program', 'required'), validate]" -->
      <editor
        api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq"
        v-model="data.description"
        placeholder="Masukan deskripsi program"
        :init="{
            height: 350,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist | link table',

              /* without images_upload_url set, Upload tab won't show up*/
              images_upload_url: 'postAcceptor.php',

              /* we override default upload handler to simulate successful upload*/
              images_upload_handler: function (blobInfo, success, failure) {
                setTimeout(function () {
                  /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                  success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                }, 2000);},
            // tinydrive_demo_files_url: '/docs/demo/tiny-drive-demo/demo_files.json',
            // tinydrive_token_provider: function (success, failure) {
            //   success({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqb2huZG9lIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Ks_BdfH4CWilyzLNk8S2gDARFhuxIauLa8PwhdEQhEo' });
            // }
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
          }"
      />
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Tipe Terjadwal</span>
      </div>
      <v-radio-group v-model="is_scheduled" @change="handleChangeScheduled">
        <v-radio value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Tampilkan Jadwal</span>
      </div>
      <v-radio-group
        :disabled="is_scheduled == 0"
        v-model="is_show_scheduled"
        @change="handleChangeShowScheduled"
      >
        <v-radio value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Jadwal</span>
      </div>
      <div class="col-md-12 py-0">
        <div class="row">
          <div class="col-md-12 ps-0">
            <v-select
              :disabled="is_scheduled == 0"
              :items="waktu"
              item-text="name"
              item-value="nilai"
              v-model="schedule.is_day"
              placeholder="Pilih Waktu"
              class="mb-2 mr-2"
            ></v-select>
          </div>
          <div v-if="schedule.is_day == 1">
            <div v-for="(item, index) in schedule.days" :key="item.day">
              <div class="row">
                <div class="col-md-5">
                  <div class="d-flex">
                    <v-select
                      :disabled="is_scheduled == 0"
                      :items="hari"
                      :item-text="'value'"
                      :item-value="'name'"
                      prepend-inner-icon="ri-calendar-event-line"
                      v-model="item.day"
                      placeholder="Pilih hari"
                      class="mb-2 mr-2"
                    ></v-select>
                    <v-btn
                      :disabled="is_scheduled == 0"
                      icon
                      @click="days.splice(index, 1)"
                      ><v-icon>$delete</v-icon></v-btn
                    >
                  </div>
                </div>
                <div class="col-md-7 pt-0">
                  <v-row>
                    <v-col
                      cols="12"
                      v-for="(time, index2) in item.times"
                      :key="index2"
                      class="d-flex"
                    >
                      <v-text-field
                        :disabled="is_scheduled == 0"
                        v-model="time.start_time"
                        prepend-inner-icon="ri-time-line"
                        placeholder="Masukkan waktu mulai"
                        class="mr-2 mb-2"
                      ></v-text-field>
                      <v-text-field
                        :disabled="is_scheduled == 0"
                        v-model="time.end_time"
                        prepend-inner-icon="ri-time-line"
                        placeholder="Masukkan waktu selesai"
                        class="mb-2"
                      ></v-text-field>
                      <v-btn
                        :disabled="is_scheduled == 0"
                        icon
                        @click="
                          item.times.length == 1
                            ? schedule.days.splice(index, 1)
                            : item.times.splice(index2, 1)
                        "
                        ><v-icon>$delete</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        :disabled="is_scheduled == 0"
                        outlined
                        @click="item.times.push({})"
                        >+ tambah waktu</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <v-col cols="12">
              <v-btn
                outlined
                :disabled="is_scheduled == 0"
                @click="
                  schedule.days.push({
                    day: '',
                    times: [{}],
                  })
                "
                >+ tambah {{ schedule.is_day == 1 ? "hari" : "tanggal" }}</v-btn
              >
            </v-col>
          </div>
          <div v-else-if="schedule.is_day == 2">
            <div v-for="(item, index) in schedule.dates" :key="item.date">
              <div class="row">
                <div class="col-md-5 ps-0">
                  <div class="d-flex">
                    <v-menu
                      :disabled="is_scheduled == 0"
                      v-model="showPicker[index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="is_scheduled == 0"
                          :value="currentDateTime(item.date)"
                          @input="item.date = $event.target.value"
                          prepend-inner-icon="ri-calendar-line"
                          placeholder="Masukkan tanggal"
                          readonly
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        :disabled="is_scheduled == 0"
                        v-model="item.date"
                        @change="currentDateTime(item.date)"
                        no-title
                        @input="showPicker[index] = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-btn
                      :disabled="is_scheduled == 0"
                      icon
                      @click="schedule.dates.splice(index, 1)"
                      ><v-icon>$delete</v-icon></v-btn
                    >
                  </div>
                </div>
                <div class="col-md-7 pt-0">
                  <v-row>
                    <v-col
                      cols="12"
                      v-for="(time, index2) in item.times"
                      :key="index2"
                      class="d-flex"
                    >
                      <v-text-field
                        :disabled="is_scheduled == 0"
                        prepend-inner-icon="ri-time-line"
                        v-model="time.start_time"
                        placeholder="Masukkan waktu mulai"
                        class="mr-2 mb-2"
                      ></v-text-field>
                      <v-text-field
                        :disabled="is_scheduled == 0"
                        prepend-inner-icon="ri-time-line"
                        v-model="time.end_time"
                        placeholder="Masukkan waktu selesai"
                        class="mb-2"
                      ></v-text-field>

                      <v-btn
                        :disabled="is_scheduled == 0"
                        icon
                        @click="
                          item.times.length == 1
                            ? schedule.dates.splice(index, 1)
                            : item.times.splice(index2, 1)
                        "
                        ><v-icon>$delete</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        :disabled="is_scheduled == 0"
                        outlined
                        @click="item.times.push({})"
                        >+ tambah waktu</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <v-col cols="12">
              <v-btn
                :disabled="is_scheduled == 0"
                outlined
                @click="
                  schedule.dates.push({
                    date: '',
                    times: [{}],
                  })
                "
                >+ tambah tanggal</v-btn
              >
            </v-col>
          </div>
        </div>
      </div>
    </v-col>

    <v-snackbar
      top
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="timeout"
    >
      <!-- :timeout="timeout" -->
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
// import { VueEditor } from "vue2-editor";
import Editor from '@tinymce/tinymce-vue'
import moment from "moment";
import model from "../_mixins/model";
import validationMixin from "../../../_mixins/validation";
import VideoInput from './VideoInput.vue';

export default {
  components: { Editor, VideoInput },
  mixins: [model, validationMixin],
  data() {
    return {
      showPicker: {},
      file_variants: [],
      variant1: {},
      variant2: {},
      variant3: {},
      image2: "",
      content: {},

      snackbar: {
        state: false,
        text: "",
      },
      timeout: 5000,

      // customToolbar: [
      //   [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      //   ["bold", "italic", "underline", "strike"], // toggled buttons
      //   [
      //     { align: "" },
      //     { align: "center" },
      //     { align: "right" },
      //     { align: "justify" },
      //   ],
      //   ["blockquote"],
      //   [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      //   [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      //   ["link", "image"],
      // ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block","link","image"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"] // remove formatting button
      ],
      customKompetensi:[
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }],      
      ],
      is_scheduled: "0",
      is_show_scheduled: "0",

      schedule: {
        is_day: "1",
        days: [{ day: "", times: [{}] }],
        dates: [{ date: "", times: [{}] }],
      },
    };
  },
  computed: {
    waktu: () => [
      {
        name: "Berdasarkan Hari",
        nilai: "1",
      },
      {
        name: "Berdasarkan Tanggal",
        nilai: "2",
      },
    ],
    hari: () => [
      {
        name: "sunday",
        value: "Minggu",
      },
      {
        name: "monday",
        value: "Senin",
      },
      {
        name: "tuesday",
        value: "Selasa",
      },
      {
        name: "wednesday",
        value: "Rabu",
      },
      {
        name: "thursday",
        value: "Kamis",
      },
      {
        name: "friday",
        value: "Jumat",
      },
      {
        name: "saturday",
        value: "Sabtu",
      },
    ],
    file() {
      if (this.data.file instanceof File) {
        const file = this.data.file;
        const type = "video";
        const url = URL.createObjectURL(file);
        return { url, type };
      } else {
        return this.data.file;
      }
    },
  },
  watch: {
    schedule: {
      deep: true,
      handler() {
        this.updateMetaData();
      },
    },
  },
  mounted() {
    this.file_variants = this.data.file_variants;
    this.file_variants.map((item) => {
      if (item.label == "original") {
        this.variant1 = item;
      } else if (item.label == "480") {
        this.variant2 = item;
      } else if (item.label == "240") {
        this.variant3 = item;
      }
    })

    const meta_data = this.data.meta_data;
    const is_scheduled = meta_data.find((v) => v.title == "is_scheduled");
    const is_show_scheduled = meta_data.find((v) => v.title == "is_show_scheduled");
    const schedules_data = meta_data.find((v) => v.title == "schedules_data");
    if (is_scheduled) this.is_scheduled = is_scheduled.content;
    if (is_show_scheduled) this.is_show_scheduled = is_show_scheduled.content;
    if (schedules_data) this.schedule = JSON.parse(schedules_data.content);
  },
  methods: {
    updateMetaData() {
      this.data.meta_data = [
        { title: "is_scheduled", content: this.is_scheduled },
        { title: "is_show_scheduled", content: this.is_show_scheduled },
        {
          title: "schedules_data",
          content: JSON.stringify(this.schedule),
        },
      ];
    },
    currentDateTime(a) {
      return a ? moment(a).locale("en-ca").format("L") : null;
    },
    handleChangeScheduled() {
      this.updateMetaData();
    },
    handleChangeShowScheduled() {
      this.updateMetaData();
    },
    deleteFileVideo(indexFile) {
      this.data.file_variants.splice(indexFile, 1);
      // this.updateTheoryData();
    },
    updateTheoryData(data) {

      // add object to file_variants with specific label
      this.file_variants[data.index] = data.data;
      // if (data.label == "original") {
      // } else if (data.label == "480") {
      //   this.file_variants[data.index] = data.data;
      //   // this.variant2 = data;
      // } else if (data.label == "240") {
      //   this.file_variants[data.index] = data.data;
      //   // this.variant3 = data;
      // }

      // this.file_variants.push(data);

      this.data.file_variants = this.file_variants
    },
    onDrop2: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange() {
      // set fiel_variants [{label: "", url: ""}]
      this.$set(this.data, "file", this.$refs.file.files[0]);
      this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
    },
    onChange2(e) {
      var files = e.target.files[0];
      this.image2 = URL.createObjectURL(files);
      this.$set(this.data, "file", e.target.files[0]);
      // this.createFile(files[0]);
      // this.uploadCover(files[0]);
    },
    uploadCover2(file) {
      var formData = new FormData();
      // let a = file;
      // console.log("img", a);

      formData.append("file", file, "fasilitator.jpg");
      formData.append("source", "upload");
      formData.append("title", "file_variants");

      // console.log("result", formData);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.content.picture.id = res.data.id;
            // console.log('result', this.id_cover)
          }
        })
        .catch(() => {
          // console.log("error", error);
        });
    },
  },
};
</script>