import { URL_API } from "../constants/api";

import axios from "axios";
// import url from "../store/api";

const SET_LIST = "SET_LIST";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, data) => (state.list = Array.isArray(data) ? data : []),
  },
  actions: {
    async get(context, payload) {
      const response = await axios
        .get(URL_API.PROGRAM.PACKET.LIST(payload.program, payload.topic, payload.activity), { params: payload.params })
        .then((res) => res.data);
      context.commit(SET_LIST, response.data.list)

      return response;
    },
    async create(context, payload) {
      const data = payload;
      const response = await axios.post(URL_API.PROGRAM.PACKET.CREATE(payload.program, payload.topic, payload.activity), data);
      await context.dispatch('get', payload)
      return response;
    },
    async delete(context, payload) {
      const data = payload;
      const response = await axios.delete(URL_API.PROGRAM.PACKET.DELETE(payload.program, payload.topic, payload.activity, payload.id), data);
      await context.dispatch('get', payload)
      return response;
    },
  },
};
