<template>
  <v-row justify="center">
    <v-col v-if="!isTrainer" cols="4">
      <v-row class="flex-column" align-content="center">
        <v-col>
          <v-img src="@/assets/images/data_empty.png" />
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada Program</div>
        </v-col>
        <div class="subtitle body-1 text-center">
          Program belum tersedia pada trainer ini
        </div>
      </v-row>
    </v-col>

    <v-col v-else>

      <v-row align="center">
        <v-col cols="5" id="search">
          <v-text-field
            append-icon="$search"
            class="pt-0 mt-0 mr-4"
            placeholder="Search"
            v-model="search"
            outlined
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="7" class="d-flex justify-end align-center">
          <v-btn
          v-if="$can('create', 'Trainer')"      
            small
            color="primary"
            elevation="0"
            @click="addTrainer">
            <v-icon>ri-add-line</v-icon> Trainer 
          </v-btn>
        </v-col> -->
      </v-row>

      <v-row>
        <v-col id="dt-program">
          <TableProgram 
            :loading="isLoading" 
            :data="data"
            @refresh-list="fetchTrainerProgram" 
            @detail="details"
            @update="update"
            @delete="deleteConfirm"
            @change_password="changePassword"
            @sorting="sorting" />
        </v-col>
      </v-row>

    </v-col>

  </v-row>
</template>

<script>
import { URL_API } from "@/constants/api";

import { mapGetters} from 'vuex';
import TableProgram from './components/TableProgram.vue';

export default {
  name: "trainer-list",
  components: {
    TableProgram
},
  data: () => {
    return {
      isTrainer: true,
      isLoading: false,
      isLoadingBtn: false,
      dialog: {
        create: false,
        delete: false,
        change_password: false,
      },

      detailTrainer: {},
      dataProgram: {},

      show_password_one: false,
      show_password_two:  false,
      show_password_three:  false,

      form: {
        // gender: 1,
        // status: 'active'
      },
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },

      search: "",
    }
  },
  watch: {
    search() {
      this.fetchTrainerProgram()
    },
    'form.email' : function (value) {
      this.form.email = value.toLowerCase()
    }
  },
  computed: {
    ...mapGetters({ data: "program/dataProgramTrainer", params: "program/paramsProgramTrainer" }),    
  },
  mounted() {
    let title = "Program Trainer"
    this.$emit("page-changed", 1, {
      title,
      link: '/trainer',
    });

    this.fetchTrainerProgram()
  },
  methods: {
    addTrainer () {
      this.dialog.create = true
      this.$refs.observer.reset()
      this.$refs.observer.resetValidation()
    },
    closeDialog() {
      this.dialog.create = false
      this.dialog.delete = false
      this.dialog.change_password = false

      this.isLoadingBtn = false
      this.isLoading = false
    },
    fetchTrainerProgram() {
      this.$store.dispatch("program/list_program_trainer",{q: this.name_program, trainer_id: this.$route.params.id }).finally(() => {
        this.table_loading = false;
      });
    },
    sorting(value) {
      if (value === true) {
        this.dir = 'desc'
      } else { this.dir = 'asc' }
      this.isLoading = true;
      this.$store.dispatch("program/list_program_trainer", { q: this.search, trainer_id: this.$route.params.id, sort: 'created_at', sort_type: 'desc' }).finally(() => {
        this.isLoading = false;
      });
    },

    details(item) {
      // console.log(item);
      this.$router.push(`/trainer/detail/${item.id}`,);
    },
    update(item) {
      // console.log(item);
      this.$router.push(`/trainer/update/${item.id}`,);
    },

    deleteConfirm(item) {
      // console.log(item);
      this.dialog.delete = true
      this.detailTrainer = item
    },

    changePassword(item) {
      // console.log(item);
      this.dialog.change_password = true
      this.detailTrainer = item
      this.form = {
        current_password: "",
        password: "",
        confirm_password: ""
      }
      this.$refs['observerPassword'].reset()
      this.$refs['observerPassword'].resetValidation()
    },

    async deleteTrainer() {
        this.isLoading = true;
        this.isLoadingBtn = true;
        await this.$store.dispatch("trainer/delete", this.detailTrainer.id)
        .then(() => {
          this.snackbar = {
            state: true,
            text: `Delete Trainer Succesfully`,
            color: "primary",
            timeout: 4000
          }
          this.dialog.delete = false
          this.fetchTrainerProgram()
        }).catch(error => {

          if(Object.keys(error.response.data.validation).length > 0) {
            Object.keys(error.response.data.validation).forEach(item => {
              this.$refs[item].applyResult({
                errors: [error.response.data.validation[item]],
                valid: false,
                failedRules: {},
              });
            })

          }
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoadingBtn = false;
          this.dialog.delete = false
          this.fetchTrainerProgram()
        });
    },
    async saveChangePassword() {
      const isValid = await this.$refs.observerPassword.validate()
      if (isValid) {
        this.isLoadingBtn = true
        let data = {
          fullname: this.detailTrainer.fullname,
          email: this.detailTrainer.email,
          phone_number: this.detailTrainer.phone_number,
          gender: this.detailTrainer.gender.value,
          current_password: this.form.current_password,
          password: this.form.password,
          confirmation_password: this.form.confirm_password,
        }
        await this.axios.put(`${URL_API.TRAINER.UPDATE(this.detailTrainer.id)}`, data).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.closeDialog()
            this.snackbar = {
              state: true,
              text: `Change Password Successfully`,
              color: "primary",
              timeout: 4000
            }
            this.fetchTrainerProgram()
          } else {
            this.closeDialog()
            this.snackbar = {
              state: true,
              text: `Change Password Failed`,
              color: "red",
              timeout: 4000
            }
          }
        }).catch(() => {
          this.error_message = this.$store.state.ContentValidation
          // if(Object.keys(error.response.data.validation).length > 0) {
          //   Object.keys(error.response.data.validation).forEach(item => {
          //     this.$refs[item].applyResult({
          //       errors: [error.response.data.validation[item]],
          //       valid: false,
          //       failedRules: {},
          //     });
          //   })

          // }
          this.isLoadingBtn = false
        })
      }
    },
    async saveTrainer() {
      let isValid = await this.$refs['observer'].validate()
      if(isValid) {
        this.isLoading = true;
        await this.axios.post(`${URL_API.TRAINER.CREATE}`, this.form)
        .then(() => {
          // console.log(res);
          this.snackbar = {
            state: true,
            text: `Create Trainer Succesfully`,
            color: "primary",
            timeout: 4000
          }
          this.dialog.create = false
          this.fetchTrainerProgram()
          this.$refs.observer.resetValidation()
          this.$refs.observer.reset()
        }).catch(error => {
          // console.log(error.response.data);
          this.isLoading = false;
          if(Object.keys(error.response.data.validation).length > 0) {
            Object.keys(error.response.data.validation).forEach(item => {
              this.$refs[item].applyResult({
                errors: [error.response.data.validation[item]],
                valid: false,
                failedRules: {},
              });
            })
          }else {
            this.snackbar = {
            state: true,
            text: error.response.data.message,
            color: "error",
            timeout: 4000
          }
          }
        })
      }
    },
  }
}
</script>