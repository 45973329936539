<template>
  <v-card elevation="0" rounded="lg" outlined>
    <v-card-text>
      <v-form ref="form" class="px-8" v-model="valid">
        <v-tabs color="primary" v-model="tabSection">
          <v-tab class="text-capitalize">Detail</v-tab>
          <v-tab class="text-capitalize">Informasi Program</v-tab>
          <v-tab class="text-capitalize">Fasilitator</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabSection">
          <v-tab-item>
            <v-row class="mt-5">
              <v-col cols="12">
                <h3 class="mb-1">Program</h3>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Judul Program</div>
                <v-text-field required filled hide-details="auto" v-model="title" placeholder="Masukan judul program"
                  :rules="[...rules('Judul Program', 'required'), validate]" class="border-0"></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Deskripsi Program</div>
                <editor api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq" v-model="program_benefits" placeholder="Masukan deskripsi program"
                  :rules="[...rules('Deskripsi program', 'required'), validate]" :init="{
                    height: 350,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                                             alignleft aligncenter alignright alignjustify | \
                                             bullist numlist | link table',

                    /* without images_upload_url set, Upload tab won't show up*/
                    images_upload_url: 'postAcceptor.php',

                    /* we override default upload handler to simulate successful upload*/
                    images_upload_handler: function (blobInfo, success, failure) {
                      setTimeout(function () {
                        /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                        success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                      }, 2000);
                    },

                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
                  }" />
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Bidang Studi</div>
                <v-autocomplete 
                  v-model="bidang_studi" 
                  :items="studi_list" 
                  :item-text="'title'"
                  :item-value="`${bidang_studi.id}, ${bidang_studi.title}`" 
                  dense
                  filled 
                  return-object
                  placeholder="Pilih bidang studi"
                  :rules="[...rules('Bidang studi', 'required'), validate]">
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-if="this.bidang_studi.title === 'Lainnya'">
                <v-text-field filled hide-details="auto" v-model="bidang_studi_add"
                  placeholder="Masukan nama bidang studi"></v-text-field>
              </v-col>

              <v-col cols="12">
                <div class="mb-2">Jenis Kelas</div>
                <v-autocomplete 
                  v-model="class_type" 
                  :items="type_class" 
                  item-text="name"
                  item-value="id" 
                  dense
                  filled 
                  placeholder="Pilih jenis kelas"
                  :rules="[...rules('Jenis kelas', 'required'), validate]">
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <div class="mb-2">Kompetensi</div>
                <div v-for="(name, index) in list_kompetensi" :key="'A' + index">
                  <div class="card mb-2">
                    <v-text-field filled hide-details="auto" v-model="list_kompetensi[index]"
                      placeholder="Masukan kompetensi" :append-outer-icon="'ri-delete-bin-line'"
                      @click:append-outer="deleteKompetensi(name.id || index)"
                      :rules="[...rules('Kompetensi', 'required'), validate]"></v-text-field>
                  </div>
                </div>
                <v-col cols="12">
                  <v-btn outlined color="primary" @click="list_kompetensi.push('')">
                    <span> <v-icon>$add</v-icon> Tambah kompetensi</span>
                  </v-btn>
                </v-col>
              </v-col>

              <!-- <v-col cols="12" v-if="this.name_kompetensi === 'Lainnya'">
              <v-text-field filled hide-details="auto" v-model="name_kompetensi" placeholder="Masukan kompetensi"></v-text-field>
            </v-col>  -->
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="mt-2">
              <v-col cols="12">
                <div class="mb-2">Cover Kelas</div>
                <div class="helper"></div>
                <div class="drop display-inline text-center" @dragover.prevent @drop="onDrop">
                  <div v-if="!image" class="content-img pt-12">
                    <p class="mb-2 pt-8"><v-icon medium>ri-image-add-line</v-icon></p>
                    <p class="mb-0 subtitle-2">Tambahkan Gambar</p>
                    <p class="mb-2 subtitle-2">dengan</p>
                    <label class="btn-img display-inline text-center">
                      Pilih Berkas
                      <input type="file" name="image" @change="onChange" accept="image/*"
                        :rules="[...rules('Cover kelas', 'required'), validate]">
                    </label>
                  </div>
                  <div class="hidden display-inline align-center py-4" v-else v-bind:class="{ 'image': true }">
                    <div class="box-img-line " style="width: 100%;margin:auto;position: relative;">
                      <img :src="image" alt="" class="img-cover" />
                      <div class="btn-float">
                        <label class="fab-white bg-label mx-2">
                          <v-icon dark>
                            ri-arrow-go-back-line
                          </v-icon>
                          <input type="file" name="image" @change="onChange" accept="image/*">
                        </label>
                        <v-btn small class="mx-2 fab-white" fab @click="removeFile">
                          <v-icon dark>
                            ri-delete-bin-7-line
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="mb-2">Tipe Program</div>
                <v-select :items="type_prog" :item-text="'name'" :item-value="'id'" placeholder="Pilih tipe program"
                  v-model="type_program" required></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="mb-2">Label Program</div>
                <v-select :items="label_prog" :item-text="'name'" :item-value="'id'" placeholder="Pilih label program"
                  v-model="label_program" required></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="mb-2">Template Sertifikat</div>
                <v-select :items="label_temp" :item-text="'name'" :item-value="'id'"
                  placeholder="Pilih template sertifikat" v-model="label_template" required></v-select>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Estimasi Waktu Pengerjaan Kelas</div>
                <v-row>
                  <v-col cols="12" md="6">
                    <!-- :rules="[...rules('Estimasi Waktu Pengerjaan Kelas', 'required'), validate]" -->
                    <v-text-field filled hide-details="auto"
                      v-model.number="estimation_duration.hour"
                      type="number" placeholder="Masukkan Estimasi Waktu Pengerjaan Kelas (Jam)" class=""></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- <div class="mb-2">Estimasi Waktu Pengerjaan Kelas <b>(Menit)</b></div> -->
                    <!-- :rules="[...rules('Estimasi Waktu Pengerjaan Kelas', 'required'), validate]" -->
                    <v-text-field filled hide-details="auto"
                      v-model.number="estimation_duration.minute"
                      type="number" placeholder="Masukkan Estimasi Waktu Pengerjaan Kelas (menit)" class="">
                    </v-text-field>
                    <div class="caption red--text">*Harap isi dengan angka 0 jika ingin mengosongkannya.</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <div class="mb-2">Harga Coret</div>
                <v-text-field filled hide-details="auto" type="number" min="0" v-model.number="harga_coret"
                  placeholder="Masukan harga coret" class="" :rules="[...rules('Harga coret', 'number'), validate]"
                  hint="Jika ingin menggunakan Harga coret harus lebih besar dari Harga jual"
                  persistent-hint></v-text-field>
                <!-- <v-text-field filled hide-details="auto" type="number" min="0" v-model.number="harga_coret" placeholder="Masukan harga coret" class="" :rules="harga"></v-text-field> -->
              </v-col>
              <v-col cols="12" md="6">
                <div class="mb-2">Harga Jual</div>
                <v-text-field filled hide-details="auto" :rules="[...rules('Harga jual', 'required'), validate]"
                  type="number" v-model.number="harga_jual" placeholder="Masukan harga jual" class=""></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="mb-2">Link Produk</div>
                <!-- <v-text-field filled hide-details="auto" :rules="[...rules('Link produk', 'required'), validate]" placeholder="Masukan link produk" class="" v-model="selling_link"></v-text-field> -->
                <div v-for="(link, index) in link_produk" :key="'L' + index">
                  <div class="d-flex">
                    <v-text-field v-model="link.title" placeholder="Masukkan judul" class="mr-2 mb-2"></v-text-field>
                    <v-text-field v-model="link.url" placeholder="Masukkan link produk" class="mb-2"></v-text-field>
                    <v-btn icon @click="link_produk.splice(index, 1);"><v-icon>$delete</v-icon></v-btn>
                  </div>
                </div>
                <v-col cols="12">
                  <v-btn outlined @click="link_produk.push({})">+ Tambah link produk</v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-expansion-panels focusable class="mt-2">
              <v-expansion-panel v-for="(fasilitator, index) in list_fasilitator" cols="12" :key="index"
                style="border: 1px solid #9db0c6;" class="mb-2">
                <v-expansion-panel-header class="font-weight-bold">
                  <h3>Fasilitator {{ index + 1 }}</h3>
                  <div class="text-right">
                    <v-btn icon @click.stop="confirmDelete(fasilitator.id || index)">
                      <v-icon> $delete </v-icon>
                    </v-btn>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-2">
                    <input-fcl 
                      :data="fasilitator" 
                      @update="$set(list_fasilitator, index, $event)" 
                      @update-trainer="setTrainerIDS" 
                      />
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-col cols="12">
              <v-btn outlined color="primary" @click="list_fasilitator.push({})">
                <span> <v-icon>$add</v-icon> Tambah fasilitator</span>
              </v-btn>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>

    <v-card-actions class="pa-4" style="background: #f8f9fa">
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" elevation="0" @click="cancel()">
        Batal
      </v-btn>
      <v-btn color="primary" elevation="0" v-if="tabSection == 0 || tabSection == 1" @click="tabSection++">
        Lanjut
      </v-btn>
      <v-btn color="primary" :loading="submitting" elevation="0" v-else @click="save">
        Simpan
      </v-btn>
    </v-card-actions>

    <v-snackbar top v-model="snackbar.state" color="primary" outlined :timeout="timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import { URL_API } from "@/constants/api";

// import { VueEditor } from "vue2-editor";
import InputFcl from "./components/fasilitator";
import { mapGetters } from "vuex";
import Editor from '@tinymce/tinymce-vue'
import validationMixin from "../_mixins/validation";

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import url from "../../store/api2";

export default {
  mixins: [validationMixin],
  name: "program-add",
  components: { 'editor': Editor, InputFcl },
  data: () => ({
    tabSection: 0,
    valid: true,
    error: null,

    media: {},
    modalMedia: false,
    label_prog:[
      { id: true, name: "Free" },
      { id: false, name: "Berbayar" }
    ],
    type_prog:[
      { id: 'online', name: "Online" },
      { id: 'offline', name: "Offline" },
      { id: 'hybrid', name: "Hybrid" }
    ],
    label_temp: [
      { id: true, name: "Program Prakerja" },
      { id: false, name: "Program Umum" }
    ],
    type_class:[
      { id: "bootcamp", name: "Bootcamp" },
      { id: "sertifikasi", name: "Sertifikasi" },
      { id: "tefa", name: "TEFA" },
      { id: "devops", name: "DevOps" },
      { id: "Pelatihan_SMK_PK", name: "Pelatihan SMK PK" },
      { id: "mini_bootcamp", name: "Mini Bootcamp" },
    ],
    jenjang: ["Paud", "Taman Kanak-Kanak", "SD", "SMP", "SMA", "Mahasiswa", "Profesional", "Umum"],
    studi: ["Bahasa Asing/Inggris", "Kuliner", "Pengembangan Diri", "Persiapan Kerja", "Strategi Marketing", "Teknologi Informasi", "Pertanian & Peternakan", "Lainnya"],
    kp: ["Mandiri", "Berorientasi Tindakan", "Komunikatif", "Persiapan Kerja", "Strategi Marketing", "Teknologi Informasi", "Lainnya"],

    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      ["blockquote", "code-block", "link", "image"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"] // remove formatting button
    ],
    customKompetensi: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ list: "ordered" }],
    ],

    bidang_studi: {},
    study_list: {},
    class_type: "",
    name_studi: "",
    selling_link: '',
    harga_jual: 0,
    harga_coret: 0,
    jam: 0,
    menit: 0,
    detik: 0,
    image: '',
    image2: '',
    id_cover: "",
    id_fasilitator: "",

    // studi: "",
    // loadingKategori: false,
    // searchKategori: "",

    title: "",
    program_benefits: "",
    estimation_duration: {
      hour: null,
      minute: null,
      second: 0
    },
    learning_targets: {},
    program_information: {},
    fasilitator: {},
    trainerIds: [],
    list_fasilitator: [],
    list_kompetensi: [],
    link_produk: [],
    value: '',
    status: "",
    bidang_studi_add: "",
    label_program: false,
    label_template: null,
    type_program: '',
    snackbar: {
      state: false,
      text: "",
      // validation:{}
    },
    timeout: 5000,
    submitting: false,
    delete_fcl: {
      show: false,
      id: null,
    },
  }),
  computed: {
    ...mapGetters("studi", {
      studi_list: "list",
      studi_detail: "detail",
    }),
    validate() {
      return !this.errors || this.errors.message;
    },
    // harga() {
    //   return [
    //     () =>
    //       this.discount_price > this.selling_price ||
    //       "Harga coret lebih besar dari Harga jual",
    //     (v) => (v >=0 || !!v) || 'Harga coret tidak boleh kosong']
    // }
  },
  mounted() {
    let title =
      this.$route.name == "add-program" ? "Tambah Program" : "Edit Program";
    this.$emit("page-changed", 2, {
      title,
      link: this.$route.path,
    });
    this.fetchStudi();
    this.list_kompetensi.push('');
    this.list_fasilitator.push({});
    this.link_produk.push({});
  },
  methods: {
    setTrainerIDS(trainer) {
      this.trainerIds = trainer
    },
    fetchStudi() {
      this.$store.dispatch("studi/list").finally(() => {
        this.loading = false;
      });
      // this.axios
      //   .get("study/v1/list?page=1&limit=-1&sort=created_at&sort_type=asc")
      //   .then((response) => {
      //     this.study_list = response.data.data.list;
      //      console.log('list',res)
      //   });
    },
    confirmDelete(id) {
      let index;

      index = this.list_fasilitator.findIndex((fasilitator) => fasilitator.id == id);
      if (index == -1) {
        index = id;
      }
      this.list_fasilitator.splice(index, 1);
    },
    deleteKompetensi(id) {
      let index;

      index = this.list_kompetensi.findIndex((list_kompetensi) => list_kompetensi.id == id);
      if (index == -1) {
        index = id;
      }
      this.list_kompetensi.splice(index, 1);
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
      this.uploadCover(files[0]);
    },
    createFile(file) {
      if (!file.type.match('image.*')) {
        alert('Select an image');
        return;
      }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image = e.target.result;
      }
      reader.readAsDataURL(file);
    },
    uploadCover(file) {
      var formData = new FormData();
      // let a = file;
      // console.log('img', a);

      formData.append("file", file, "cover.jpg");
      formData.append("source", "upload");
      formData.append("title", "cover kelas");

      // console.log('result', formData);
      this.snackbar.text = 'Loading Upload ...';
      this.snackbar.state = true;
      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.id_cover = res.data.id;
            // console.log('result', this.id_cover)
            this.snackbar.text = 'Sukses Upload Cover Program';
            this.snackbar.state = true;
          }
        })
        .catch(() => {
          // console.log("error", error);
        });
    },
    removeFile() {
      this.image = '';
    },
    onDrop2: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange2(e) {
      var files = e.target.files;
      this.createFile2(files[0]);
      this.uploadCover2(files[0]);
    },
    createFile2(file) {
      if (!file.type.match('image.*')) {
        alert('Select an image');
        return;
      }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image2 = e.target.result;
      }
      reader.readAsDataURL(file);
    },
    uploadCover2(file) {
      var formData = new FormData();
      // let a = file;
      // console.log('img', a);

      formData.append("file", file, "fasilitator.jpg");
      formData.append("source", "upload");
      formData.append("title", "fasilitator");

      // console.log('result', formData);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          if (res.status == 200) {
            this.id_fasilitator = res.data.id;
            // console.log('result', this.id_cover)
          }
        })
        .catch(() => {
          // console.log("error", error);
        });
    },
    removeFile2() {
      this.image2 = '';
    },
    cancel() {
      this.$router.push("/program");
    },
    save() {
      if(this.trainerIds.length == 0) {
        this.snackbar.state = true
        this.snackbar.text = "Maaf, belum ada trainer yang dipilih"
        return
      }

      this.errors = null;
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submitting = true;
          if (this.bidang_studi.title == 'Lainnya') {
            let add = {
              title: this.bidang_studi_add
            }
            this.axios
              .post(`${URL_API.PROGRAM.STUDY.CREATE}`, add)
              .then((res) => {
                // console.log(res);
                let id_study = res.data.data.id;
                let params = {
                  title: this.title,
                  type: this.type_program,
                  program_benefits: this.program_benefits,
                  flag: this.class_type,
                  learning_targets: {
                    studies: {
                      id: id_study,
                      title: this.bidang_studi_add
                    },
                    competence: this.list_kompetensi
                  },
                  program_information: {
                    cover: this.id_cover,
                    discount_price: this.harga_coret,
                    selling_price: this.harga_jual,
                    // selling_link : this.selling_link,
                    selling_link: this.link_produk,
                  },
                  trainer_ids: this.trainerIds,
                  fasilitator: this.list_fasilitator,
                  estimation_duration: this.estimation_duration,
                  status: 'draft',
                  is_free: this.label_program,
                  is_prakerja: this.label_template
                }
                // console.log('data', params);
                this.axios
                  .post(`${URL_API.PROGRAM.CREATE}`, params)
                  .then(() => {
                    this.submitting = false;
                    this.snackbar.text = `Successfully added program`;
                    this.snackbar.state = true;
                    setTimeout(() => { this.$router.push({ name: "list-program" }); }, 3000);
                  })
                  .catch((res) => {
                    this.submitting = false;
                    if (res.response) {
                      this.snackbar.text = res.response.data.message;
                      this.snackbar.state = true;
                    }
                  });
              })
              .catch((res) => {
                this.submitting = false;
                if (res.response) {
                  this.snackbar.text = res.response.data.message;
                  // this.snackbar.validation = res.response.data.validation;
                  this.snackbar.state = true;
                }
              });
          } else {
            let params = {
              title: this.title,
              type: this.type_program,
              flag: this.class_type,
              program_benefits: this.program_benefits,
              learning_targets: {
                studies: {
                  id: this.bidang_studi.id,
                  title: this.bidang_studi.title
                },
                competence: this.list_kompetensi
              },
              program_information: {
                cover: this.id_cover,
                discount_price: this.harga_coret,
                selling_price: this.harga_jual,
                // selling_link : this.selling_link
                selling_link: this.link_produk
              },
              trainer_ids: this.trainerIds,
              fasilitator: this.list_fasilitator,
              estimation_duration: this.estimation_duration,
              status: 'draft',
              is_free: this.label_program,
              is_prakerja: this.label_template
            }
            // console.log('data',params);
            this.axios
              .post(`${URL_API.PROGRAM.CREATE}`, params)
              .then(() => {
                this.submitting = false;
                this.snackbar.text = 'Successfully added program';
                this.snackbar.state = true;
                setTimeout(() => { this.$router.push({ name: "list-program" }); }, 3000);
              })
              .catch((res) => {
                this.submitting = false;
                if (res.response) {
                  // this.snackbar.text = res.response.data.message;
                  if(Object.keys(res.response.data.validation).length > 0) {
                    Object.keys(res.response.data.validation).forEach((item) => {
                      this.snackbar.text += `${res.response.data.validation[item]}`
                    })
                  }else {
                    this.snackbar.text = res.response.data.message;
                  }
                  this.snackbar.state = true;
                }
              });
          }
        }
        else {
          this.snackbar.text = "Data cannot be empty, Please check your data";
          this.snackbar.state = true;
        }
      }, 50);
    }
  },
};
</script>
<style>
.ql-editor {
  background: #F4F6F9;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.btn-img {
  background-color: #1A56DB;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 12px 16px;
  position: relative;
  border-radius: 4px;
}

.drop input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.drop {
  background-color: #F4F6F9;
  border: 1px dashed #ccc;
  background-color: #F4F6F9;
  border-radius: 2px;
  /*height: 250px;*/
  height: 320px;
  width: 100%;
  vertical-align: middle;
}

.img-cover {
  width: 100%;
  object-fit: contain;
  height: 250px;
}

.btn-float {
  position: absolute;
  top: 10%;
  right: 5%;
}

.fab-white {
  background-color: #fff !important;
  color: #4c6583 !important;
}

.fab-white i {
  color: #4c6583 !important;
}

.bg-label {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  padding: 10px;
}
</style>