<template>
  <v-card class="pa-4">
    <form ref="form">
      <v-row>
        <v-col id="search" cols="12">
          <div class="mb-2">Nama Perusahaan</div>
          <v-text-field  
            placeholder="Nama Perusahaan" 
            outlined
            color="primary"
            v-model="title">
          </v-text-field>        
        </v-col>
        <v-col cols="12">
          <div class="mb-2">Konten Privacy Policy</div>
          <editor api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq" v-model="content" placeholder="Masukan privacy policy"
            :init="{
              height: 350,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist | link table',
              images_upload_url: 'postAcceptor.php',
              images_upload_handler: function (blobInfo, success, failure) {
                setTimeout(function () {
                  success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                }, 2000);
              },
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
            }" />
        </v-col>
        <v-col cols="12">
          <div>Status Privacy Policy</div>
          <v-switch
            v-model="is_status"
            :label="is_status ? 'Aktif' : 'Tidak aktif'">
          </v-switch>
        </v-col>
      </v-row>

      <v-card-actions class="pb-2 px-7">
        <v-spacer></v-spacer>
        <v-btn 
          width="100"
          elevation="0"
          color="primary"
          class="text-capitalize white--text"
          :loading="process.run"
          :disabled="process.run"
          @click="save()">
          Simpan
        </v-btn>
      </v-card-actions>
    </form>

    <v-snackbar v-model="snackbar.state" :color="snackbar.color" :timeout="5000">
      {{ snackbar.text }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { URL_API } from '@/constants/api'

import Editor from '@tinymce/tinymce-vue'
export default {
  components: {
    Editor
  },
  data: () => ({
    title: "",
    content: "",
    slug:"",
    is_status: false,
    detail_company: {},
    process: {
      run: false,
    },
    snackbar: {
      state: false,
      text: "",
      color: ""
    }
  }),
  created() {
    this.detailCompany()
    this.detailPrivacyPolicy()
  },
  methods: {
    async save() {
      this.process.run = true
      const data = {
        title: this.title,
        content: this.content,
        slug: this.slug,
        is_status: this.is_status,
        company_id: this.detail_company.id
      }
      this.axios.post(`${URL_API.PAGES.PRIVACY_POLICY.CREATE}`, data)
        .then(response => {
          let res = response.data
          this.snackbar = {
            state: true,
            text: res.message,
            color: 'success'
          }
          this.process.run = false
        })
        .catch(error => {
          let err = error.response.data
          this.snackbar = {
            state: true,
            text: err.message,
            color: 'error'
          }
          this.process.run = false
        })
    },
    detailPrivacyPolicy() {
      this.axios.get(`${URL_API.PAGES.PRIVACY_POLICY.DETAIL('privacy-policy')}`)
        .then(response => {
          let res = response.data
          this.title = res.data.title
          this.slug = res.data.slug
          this.content = res.data.content
          this.is_status = res.data.is_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    detailCompany() {
      this.axios.get(`${URL_API.COMPANY.DETAIL}`)
        .then(response => {
          let res = response.data.data
          this.detail_company = res
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>